import React, { useEffect, useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import Molecule from "../../../../MComponent/Molecule";
import { getData, getUserId } from "../../../../Utils/Common/Library";
import { Box, Radio, Rating, Typography } from "@mui/material";
import ActorAccordian from "./ActorAccordian";
import { WorkflowProcesser } from "../WorkflowProcesser";
import GroupAccordian from "../GroupAccordian";
import { PeriodMap } from "./PeriodMap";
import PhaseAccordian from "../PhaseAccordian";
import store from "../../../../Redux/Store";
import AlertMessage from "../../../../Utils/Messages/AlertMessage";

const { Button, Input, Dropdown } = Atomic;
const { EwModal, Tabs, Table } = Molecule;

const CurrentActorUserID = "3";
const CurrentActorType = "R";

const actorMap = {
  S: "Appraisee",
  A: "Appraiser",
  R: "Reviewer",
};

function isViewable(ActorType) {
  return true;
  if (CurrentActorType === "S") {
    return ActorType === "S";
  } else if (CurrentActorType === "A") {
    return ActorType === "S" || ActorType === "A";
  } else {
    return true;
  }
}

function AssessmentForm({
  handleTabEdit,
  ActorType,
  onChangeAssessment,
  ActorUserID,
  Score,
  Achievement,
  RatingValue,
  RatingScaleValueID,
  Workflow,
  ParameterWeightage,
  TargetRule,
  scales,
  AchievementDataType,
  disable,
  onSaveAssessment,
  closeForm,
  view,
  actions,
  ItemNumber,
  prevAssesmentObject,
  StepOwnerLabel,
  Achievements,
}) {
  console.log("Item Number is: ", prevAssesmentObject);

  const [state, setState] = useState({
    ActorType: ActorType,
    ActorUserID: ActorUserID,
    Score: Score,
    Achievement: Achievement,
    RatingValue: RatingValue,
    RatingScaleValueID: RatingScaleValueID,
    ItemNumber,
  });
  const [actionError, setActionError] = useState(null);
  console.log("State is: ", state, disable);
  const [otherStates, setOtherStates] = useState({
    isLoading: false,
    message: null,
  });
  const { isLoading } = otherStates;
  console.log(scales, "These are the scales");
  function updateOtherStates(val, type) {
    handleTabEdit && handleTabEdit();
    console.log("While updateing state: ", val, type, " ", state);
    setOtherStates((prev) => ({ ...prev, [type]: val }));
  }
  if (!view) {
    return <></>;
    // if (prevAssesmentObject) {
    //  // const {} = prevAssesmentObject;
    //   return <></>;
    // } else {
    //   return <></>;
    // }
  }
  //console.log(scales, state, "These are the scales");

  const workflowProcesser = new WorkflowProcesser(Workflow);
  const WorkflowStepUserID = workflowProcesser.getCurrentStepUserID(); //returns userid

  const WorkflowStep = workflowProcesser.getCurrentStep(); //returns index of step
  const CurrentUserStep = workflowProcesser.getStepByIndex(WorkflowStep);
  const ActorStep = workflowProcesser.getStepByStepUserID(ActorUserID);

  //if (!WorkflowStepUserID || WorkflowStep === -1) return <></>;

  // if (!WorkflowStepUserID) {
  //   if (!ActorStep || WorkflowStep <= ActorStep)
  //     return (
  //       <div className="Flex">
  //         <Typography variant="h6">
  //           You are not allowed to view this content.
  //         </Typography>
  //       </div>
  //     );
  // }

  let achivementDisable = disable;
  let DropdownDisable = disable;
  const { targetType } = TargetRule;
  if (disable === false) {
    if (targetType === "AR") {
      achivementDisable = true;
    } else if (targetType === "CR") {
      DropdownDisable = true;
    }
  }
  function onChangeHandler(value, type) {
    handleTabEdit && handleTabEdit();
    setState((prevState) => {
      const newState = { ...prevState, [type]: value };
      onChangeAssessment(ActorType, newState);
      return newState;
    });
  }
  const isNumericAchivement = ["NI", "ND", "PI", "PD"].includes(
    AchievementDataType
  );
  function updateRating() {
    let Achivement = +state.Achievement;
    if (targetType === "CR") {
      handleTabEdit && handleTabEdit();
      setState((prevState) => {
        const newState = {
          ...prevState,
          RatingScaleValueID: scales[0][0],
          Achievement: Achivement,
        };
        onChangeAssessment(ActorType, newState);
        return newState;
      });
    }
  }
  function temp(obj) {
    console.log("Test temp obj: ", obj);
  }
  function getScoreValue(id) {
    let index = scales.findIndex((item) => item[0] === id);
    if (index == -1) return "";
    return scales[index][3];
  }
  return !view ? (
    <></>
  ) : (
    <div className="Flex" style={{ gap: "2em", alignItems: "normal" }}>
      {
        <div>
          <Typography>{ActorType}</Typography>

          <br />

          <>
            <Dropdown
              disable={DropdownDisable}
              menuItems={[
                ...scales.map((scale) => ({
                  label: scale[1],
                  value: scale[0],
                })),
              ]}
              onChange={(value) => {
                // setState((prevState) => ({
                //   ...prevState,
                //   //  Score: getScoreValue(value),
                //   RatingScaleValueID: value,
                // }));
                if (value === -1) return;
                if (!ParameterWeightage) ParameterWeightage = 0;
                const scale = scales.find((scale) => scale[0] === value);
                let scaleValue = 0;
                if (scale) scaleValue = scale[3];
                console.log(
                  scaleValue,
                  " ",
                  state,
                  "ASSESSMENT SUBMIT TRIGGERED"
                );
                handleTabEdit && handleTabEdit();
                setState((prevState) => {
                  const newState = {
                    ...prevState,
                    Score: scaleValue,
                    RatingScaleValueID: value,
                  };
                  onChangeAssessment(ActorType, newState);
                  return newState;
                });
              }}
              value={state.RatingScaleValueID}
              required={true}
            />
            <br />
            {achivementDisable ? (
              <Box sx={{ width: "15em" }}>
                {!state.Achievement ? (
                  <Typography variant="subtitle2">
                    {"No Comments added"}
                  </Typography>
                ) : (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Typography variant="subtitle1">Comments</Typography>
                    <Typography variant="subtitle2" textAlign={"left"}>
                      {state.Achievement}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Input
                label={"Comments"}
                disable={achivementDisable}
                value={state.Achievement}
                styles={{ margin: 0 }}
                type={isNumericAchivement ? "number" : "text"}
                rows={!isNumericAchivement && 4}
                onChange={(value) => onChangeHandler(value, "Achievement")}
                onBlur={updateRating}
                required={true}
              />
            )}
          </>
        </div>
      }
      {/*Added a comment  */}
      {/* <div className="Flex">
        {!disable && (
          <Button
            isLoading={isLoading}
            title={"Save As Draft"}
            // title={
            //   state.Achievement !== "" ||
            //   state.RatingScaleValueID !== "" ||
            //   state.RatingValue !== ""
            //     ? "Edit"
            //     : "Save"
            // }
            onClick={() => {
              handleTabEdit && handleTabEdit("save");
              console.log({ ...state }, "Assessment before submit");
              // let obj = { ...state };

              if (!state.Achievement) {
                setActionError({ type: 1, message: "Please add a comment" });
                return;
              }

              updateOtherStates(true, "isLoading");
              // console.log(
              //   JSON.stringify({ ...state }),
              //   "ASSESSMENT SUBMIT TRIGGERED 111"
              // );
              console.log(
                "Before sub1: ",
                { ...state },
                " ",
                JSON.parse(JSON.stringify(state))
              );
              temp(state);
              onSaveAssessment(
                JSON.parse(JSON.stringify(state)),
                actions,
                updateOtherStates
              );
              closeForm();
            }}
          />
        )}
        <br />
        {actionError && (
          <AlertMessage {...actionError} onClose={() => setActionError(null)} />
        )}
      </div> */}
    </div>
  );
}

function AssessmentModal({
  AssessmentData,
  AssessmentPeriod,
  TargetDataType,
  AchievementDataType,
  onChangeAssessment,
  TargetRule,
  handleTabEdit,
  RatingScaleID,
  ParameterWeightage,
  // ActionStatusLog,
  Workflow,
  closeForm,
  onSaveAssessments,
  actorAssessmentForParams,
  Achievements,
}) {
  if (ParameterWeightage === "%") ParameterWeightage = null;
  console.log("Weight is: parmaeytr", AssessmentData);
  const userId = getUserId();
  if (!actorAssessmentForParams) actorAssessmentForParams = [];
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(null);
  const workflowProcesser = new WorkflowProcesser(Workflow);
  const WorkflowStepUserID = workflowProcesser.getCurrentStepUserID(); //returns userid
  const totalSteps = workflowProcesser.getAllSteps() - 1;
  const WorkflowStep = workflowProcesser.getCurrentStep(); //returns index of step
  const CurrentUserStep = workflowProcesser.getStepByIndex(WorkflowStep);
  const stepOfLoggedInUser = workflowProcesser.getStepOfLoggedInUser(userId); //index of step

  const isActionOwner = userId === WorkflowStepUserID;
  const { StepOwnerType, StepOwner, StepID, StepOwnerLabel } =
    CurrentUserStep || {};
  let message = "";
  let isEditable = [false, false];
  let actions = [];
  let view = [false, false];
  let prevAssesment = [];
  console.log(
    "assesment data: ",
    totalSteps,
    " ",
    stepOfLoggedInUser,
    " ",
    isActionOwner
  );
  if (stepOfLoggedInUser === -1) {
  } else if (WorkflowStep == -1) {
    view = [true];
    if (totalSteps === 3) view.push(false);
    message = "Assesment Complete";
  } else {
    if (isActionOwner) {
      if (StepOwnerType === "SU") {
        view = [true];
        prevAssesment = [actorAssessmentForParams[1]];
        if (WorkflowStep === 3) {
          view.push(false);
          prevAssesment.push(actorAssessmentForParams[2]);
        }
        actions = [null, "Approve", null];
        isEditable = [false, false];
        // console.log("Viewable 1", view);
      } else if (StepOwnerType === "LM" && StepOwner === "1") {
        view = [true];
        prevAssesment = [actorAssessmentForParams[1]];
        isEditable = [true, false];
        actions = [null, "Submit", null];
        console.log("Viewable 1", view);
      } else if (StepOwnerLabel === "Moderator") {
        view = [true];
        prevAssesment = [actorAssessmentForParams[1]];
        isEditable = [false, false];
      } else {
        view = [true, false];
        prevAssesment = [
          actorAssessmentForParams[1],
          actorAssessmentForParams[2],
        ];
        isEditable = [false, false];
        actions = [null, null, "Submit"];
      }
    } else {
      isEditable = [false, false];
      if (totalSteps === 2) {
        if (stepOfLoggedInUser === 1) {
          //Appraiser is viewing
          view = [true];
          message = "Appraisee Has Not Taken An Action";
        } else {
          message = "Assessment Not Done";
        }
      } else if (totalSteps === 3) {
        console.log("Step is: from if", stepOfLoggedInUser, " ", WorkflowStep);
        if (stepOfLoggedInUser < WorkflowStep) {
          view = [true];
          message = "Waiting For Assesment By Reviewer";
          if (stepOfLoggedInUser === 2) {
            view.push(true);
            message = "Appraisee Has Not Taken An Action";
          }
        } else {
          message = "Waiting For Assesment";
        }
      } else {
        if (stepOfLoggedInUser < WorkflowStep) {
          view = [true];
          message = "Appraisee Has Not Taken An Action";
          /// message = "Waiting For Assesment By Reviewer";
          // if (stepOfLoggedInUser === 2) {
          //   view.push(true);
          //   message = "Appraisee Has Not Taken An Action";
          // }
        }
      }
    }
  }

  console.log(view, "VIEWABLE");
  // console.log(ActionStatusLog, "ACTION STATUS LOG IS THIS");
  AssessmentData[0].view =
    workflowProcesser.getWorkflowStatus() === "C" ? true : false;
  AssessmentData[1].view =
    workflowProcesser.getWorkflowStatus() === "C" ? true : view[0];
  AssessmentData[2].view =
    workflowProcesser.getWorkflowStatus() === "C" ? true : false;

  AssessmentData[0].disable =
    workflowProcesser.getWorkflowStatus() === "C" ? true : undefined;
  AssessmentData[1].disable =
    workflowProcesser.getWorkflowStatus() === "C" ? true : !isEditable[0];
  AssessmentData[2].disable =
    workflowProcesser.getWorkflowStatus() === "C" ? true : !isEditable[1];

  console.log("prev assesm,ent 1: ", prevAssesment, " ", AssessmentData);

  // AssessmentData[1].prevAssesmentObject = { ...prevAssesment[0] };
  // AssessmentData[2].prevAssesmentObject = { ...prevAssesment[1] };
  console.log("Assement log:2 ", AssessmentData);
  useEffect(() => {
    if (RatingScaleID) {
      setLoading(true);
      getData({
        featureId: "19148",
        payload: { "Header.TransactionID": RatingScaleID },
      })
        .then((response) => {
          setScale(response.Data[0]);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [RatingScaleID]);

  const Targets =
    TargetRule.targetType === "FV" || TargetRule.targetType === "ER"
      ? AssessmentPeriod.SingleTarget
      : AssessmentPeriod.MultipleTargets;
  console.log("Assessment taregts: ", TargetRule.targetType, " ", Targets);
  let RatingScale;
  if (
    ["FV", "ER"].includes(TargetRule.targetType) ||
    TargetRule.enabled === "D"
  ) {
    console.log("Inside FV ", scale?.ScaleValues);
    RatingScale = (scale?.ScaleValues || []).map(
      ({ OptionID, Label, Value }) => {
        return [OptionID, Label, "", Value];
      }
    );
  } else {
    RatingScale = Targets.map((target) => {
      const ScaleOption = (scale?.ScaleValues || []).find(
        (value) => value.OptionID === target.ScaleOptionID
      );
      console.log(target, ScaleOption, "THESE ARE THE VALUES");
      const result = [
        ScaleOption?.OptionID,
        ScaleOption?.Label,
        TargetDataType === "ST" || TargetDataType === "LT"
          ? target.SingleValue
          : `${target.MinimumRangeValue} - ${target.MaximumRangeValue}`,
        ScaleOption.Value,
      ];
      return result;
    });
  }

  console.log("rating scale is: ", AssessmentData);
  console.log(view, "VIEWABLE");
  const componentList = [
    ...AssessmentData.map((assess, index) => {
      const finalAssess = {
        //label: actorMap[assess.ActorType],
        label: "",
        Component: AssessmentForm,
        props: {
          ...assess,
          Achievements,
          handleTabEdit,
          TargetDataType,
          onChangeAssessment,
          TargetRule,
          RatingScaleID,
          AchievementDataType,
          // ActionStatusLog,
          ParameterWeightage,
          scales: RatingScale,
          Workflow,
          actorAssessmentForParams,
          actions: actions[index],
          StepOwnerLabel,
          // disable: assess.ActorUserID !== CurrentActorUserID,
          onSaveAssessment: (updatedAssessment, actions, updateOtherStates) => {
            const {
              Achievement,
              ActorUserID,
              ActorType,
              ItemNumber,
              RatingScaleValueID,
              RatingValue,
              Score,
            } = updatedAssessment;
            console.log(
              "Assessment before submit",
              updatedAssessment,
              " ",
              actions,
              " ",
              updateOtherStates
            );
            // let updatedAssessment1 = new Object({
            //   Achievement,
            //   ActorUserID,
            //   ActorType,
            //   ItemNumber,
            //   RatingScaleValueID,
            //   RatingValue,
            //   Score,
            // });
            // let coppyOfAsses=[...AssessmentData]
            // for(let i=0;i<coppyOfAsses.length;i++){
            //   console.log("Item is: 11",coppyOfAsses[i]," ", coppyOfAsses[i].view);
            //   coppyOfAsses[i].view=null;
            //   console.log("Item is: 11 a",coppyOfAsses[i]);
            // }

            //   console.log("Afetr delete: ", [...coppyOfAsses])
            const prevAssessments = [...AssessmentData];
            const index = prevAssessments.findIndex(
              (pa) => pa.ActorType === assess.ActorType
            );
            if (index !== -1) {
              prevAssessments[index] = JSON.parse(
                JSON.stringify(updatedAssessment)
              );
              console.log("prev updated: ", prevAssessments[index]);
            }
            const newAssessments = [...prevAssessments];
            console.log(
              "Inside assesmnet save: ",
              prevAssessments,
              " ",
              newAssessments
            );

            onSaveAssessments(newAssessments, {
              stepId: StepID,
              action: actions,
              updateOtherStates,
            });
          },
          closeForm,
        },
        // disable: false,
      };

      return finalAssess;
    }),
  ];

  console.log(Rating, "THISIS MY RATING");
  const isTragetEnabled = TargetRule.enabled === "E";
  function renderTargets() {
    if (TargetRule.targetType === "FV") {
      return "Fixed Value";
    }
    return (
      <>
        {" "}
        {RatingScale.map((scale) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="tinytext">{scale[1]}</Typography>
            <Typography variant="tinytext">{scale[2]}</Typography>
          </div>
        ))}
      </>
    );
  }
  return loading || !scale ? (
    <div className="Flex">Loading ...</div>
  ) : (
    <div style={{ display: "flex" }}>
      <div style={{ width: "10em", paddingRight: "3em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5em",
          }}
        >
          {ParameterWeightage && (
            <>
              <Typography variant="h4">Weight</Typography>
              <Typography variant="body2">{`[${ParameterWeightage}]`}</Typography>
            </>
          )}
        </div>
        {isTragetEnabled && (
          <div>
            <Typography variant="h4" sx={{ marginBottom: "0.5em" }}>
              Target
            </Typography>
            {renderTargets()}
          </div>
        )}
      </div>
      <Typography>{message}</Typography>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {componentList.map((component) => {
          return isViewable(component.props.ActorType) ? (
            workflowProcesser.getWorkflowStatus() === "C" &&
            (RatingScaleID
              ? component.props.RatingScaleValueID === ""
              : component.props.RatingValue === "") ? (
              <></>
            ) : (
              <div style={{ textAlign: "center" }}>
                {/* <Typography variant="caption" fontWeight={600}>
                {component.label}
              </Typography> */}
                <component.Component {...component.props} />
              </div>
            )
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
}

function Assessment({
  collapse,
  handleTabEdit,
  onChangeAssessment,
  TransactionID,
  ParameterRatingScaleID,
  ParameterTargetDataType,
  ParameterTargetRule,
  ParameterWeightage,
  ParameterAchievementDataType,
  AssessmentPeriodType,
  AssessmentPeriodValue,
  AssessmentStartDate,
  AssessmentEndDate,
  ParameterTargets,
  // ActionStatusLog,
  Workflow,
  ParameterActorAssessments,
  onSave,
  AssessmentDocumentID,
  actorAssessment,
  Achievements,
}) {
  console.log(handleTabEdit, "handleTabEditAssessment");
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  //,
  console.log("Workflow object is: ", actorAssessment);
  let actorAssessmentForParams;
  if (actorAssessment)
    actorAssessmentForParams = actorAssessment[AssessmentDocumentID];
  if (actorAssessmentForParams)
    actorAssessmentForParams = actorAssessmentForParams[TransactionID];
  const { enabled: TargetEnabled } = ParameterTargetRule;
  if (
    !ParameterTargetRule ||
    !AssessmentPeriodType ||
    !AssessmentPeriodValue ||
    !ParameterTargets ||
    !ParameterActorAssessments ||
    (TargetEnabled === "E" &&
      (!ParameterTargetDataType || !ParameterAchievementDataType))
  ) {
    console.log(
      ParameterTargetDataType,
      ParameterTargetRule,
      ParameterAchievementDataType,
      AssessmentPeriodType,
      AssessmentPeriodValue,
      ParameterTargets,
      ParameterActorAssessments,
      "THESE ARE PROPS"
    );

    return (
      <div className="Flex">
        <Typography>Data missing for assessment component</Typography>
      </div>
    );
  }

  return (
    <div>
      <PhaseAccordian
        collapse={collapse}
        TransactionID={TransactionID}
        title={
          AssessmentPeriodType === "I"
            ? `${PeriodMap[AssessmentPeriodType].label} (${AssessmentPeriodValue})`
            : PeriodMap[AssessmentPeriodType].values[AssessmentPeriodValue]
        }
        caption={`${AssessmentStartDate} - ${AssessmentEndDate}`}
        // completed={ActionStatusLog.charAt(3) === "C"}
        completed={Workflow?.WorkflowStatus === "C"}
      >
        <AssessmentModal
          AssessmentPeriod={ParameterTargets.find(
            (pt) =>
              // pt.TargetPeriodType === AssessmentPeriodType &&
              pt.TargetPeriodValue === AssessmentPeriodValue
          )}
          Achievements={Achievements}
          onChangeAssessment={onChangeAssessment}
          handleTabEdit={handleTabEdit}
          AssessmentData={ParameterActorAssessments}
          TargetDataType={ParameterTargetDataType}
          AchievementDataType={ParameterAchievementDataType}
          // ActionStatusLog={ActionStatusLog}
          ParameterWeightage={ParameterWeightage}
          Workflow={Workflow}
          TargetRule={ParameterTargetRule}
          RatingScaleID={ParameterRatingScaleID}
          actorAssessmentForParams={actorAssessmentForParams}
          onSaveAssessments={(updatedAssessments, workflowData) =>
            onSave(updatedAssessments, AssessmentDocumentID, workflowData)
          }
          closeForm={() => setModalOpen(false)}
        />
      </PhaseAccordian>
    </div>
  );
}

export default Assessment;
