import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Molecule from "../../MComponent/Molecule";
import { complexQuery } from "../../Utils/Common/Library";
import { constants } from "./Dimension/Data/QueryConstants";
import Dropdown from "../../AComponent/Dropdown/Dropdown_3";

const { Table } = Molecule;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ boxSizing: "border-box", borderRadius: "5px" }}>
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Average Score
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.average}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Rating Label
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.label}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Number Of Employees
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.users.length}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

function EPPScoreDistribution({ asrScore, aggScore, multiple }) {
  const [lineChartSelected, setLineChartSelected] = useState({});
  const palette = useTheme().palette;
  const [scaleProp, setScaleProp] = useState(
    asrScore?.length > 0 ? "AssessorPlanScore" : "AggregatedPlanScore"
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" color={"primary"}>
          Score Distribution
        </Typography>
        {multiple && (
          <Dropdown
            label={"Select Score Type"}
            menuItems={[
              { label: "Assessor Plan Score", value: "AssessorPlanScore" },
              { label: "Aggregate Plan Score", value: "AggregatedPlanScore" },
            ]}
            onChange={(value) => {
              setScaleProp(value);
            }}
            value={scaleProp}
            size={"small"}
          />
        )}
      </Box>
      <br />
      <Box display={"flex"} height={"80vh"} className="invisble-scroll">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "0em",
          }}
        >
          <ComposedChart
            width={800}
            height={300}
            data={scaleProp === "AssessorPlanScore" ? asrScore : aggScore}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="range"
              label={{
                value: "Rating Scale",
                position: "insideMiddle",
                dy: 20,
              }}
              name="Rating Scale"
            />
            <YAxis
              label={{
                value: "Number of Employees",
                angle: -90,
                position: "insideMiddle",
                dx: -20,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              type="monotone"
              dataKey="numEmployees"
              fill={`${palette.primary.main}`}
              onClick={(e) => {
                setLineChartSelected((prevState) => ({ ...prevState, Bar: e }));
              }}
            />
            <Line
              type="monotone"
              dataKey="numEmployees"
              stroke={`${palette.primary.main}`}
              activeDot={{
                r: 7,
                onClick: (data, index) => {
                  setLineChartSelected((prevState) => ({
                    ...prevState,
                    Line: index,
                  }));
                },
              }}
            />
          </ComposedChart>
          {lineChartSelected["Bar"] && (
            <Box>
              <Table
                heading={["Username", "Score"]}
                rows={[
                  ...lineChartSelected["Bar"].payload.users.map((user) => {
                    return [user.AppraiseeFullName, user[scaleProp] || "0"];
                  }),
                ]}
                pageLimit={5}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

function Dashboard({ AppraiserID, ReviewerID }) {
  const [asrScore, setAsrScore] = useState([]);
  const [aggScore, setAggScore] = useState([]);
  const [loading, setLoading] = useState(false);

  function generateScoreDistribution(epps, scaleProp) {
    const scaleMap = {
      1: "Very Poor",
      2: "Poor",
      3: "Average",
      4: "Good",
      5: "Very Good",
    };

    const buckets = {
      0.5: [],
      1: [],
      1.5: [],
      2: [],
      2.5: [],
      3: [],
      3.5: [],
      4: [],
      4.5: [],
      5: [],
    };

    function findAverage(value) {
      let total = 0;

      for (let i = 0; i < value.length; i++) {
        total += +value[i][scaleProp] || 0;
      }

      return +total / value.length || 0;
    }

    epps.forEach((epp) => {
      const keys = Object.keys(buckets).sort((a, b) =>
        a > b ? 1 : b > a ? -1 : 0
      );

      for (let i = 0; i < keys.length; i++) {
        if (parseFloat(epp.EppData[scaleProp] || "0") <= parseFloat(keys[i])) {
          buckets[keys[i]].push({
            ...epp.EppData,
            ...epp.EppHeader,
            AppraiseeFullName: epp.AppraiseeFullName,
          });
          break;
        }
      }
    });

    const data = [];

    Object.entries(buckets)
      .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
      .forEach(([key, value]) => {
        const low = parseFloat(key) - 0.5,
          high = parseFloat(key);

        data.push({
          range: `${low} - ${high}`,
          label: scaleMap[low] || scaleMap[high] || "Unrated",
          numEmployees: value.length,
          users: value,
          average: findAverage(value),
        });
      });

    return data;
  }

  useEffect(() => {
    setLoading(true);
    if (ReviewerID) {
      complexQuery({
        QueryName: constants.FindAllEPPByReviewerID,
        RequiredFields: [
          {
            TenantCode: "ROHIT",
          },
          {
            ReviewerID: ReviewerID,
          },
        ],
      })
        .then((res) => {
          setAsrScore(generateScoreDistribution(res, "AssessorPlanScore"));
          setAggScore(generateScoreDistribution(res, "AggregatedPlanScore"));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (AppraiserID) {
      complexQuery({
        RequiredFields: [
          {
            TenantCode: "ROHIT",
          },
          {
            AppraiserID: AppraiserID,
          },
        ],
        QueryName: constants.FindAllEPPByAppraiserID,
      })
        .then((res) => {
          setAggScore(generateScoreDistribution(res, "AggregatedPlanScore"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return loading ? (
    <>Loading ... </>
  ) : (
    <Box sx={{ height: "700px" }} className="scroll">
      <br />
      {ReviewerID && (
        <EPPScoreDistribution
          asrScore={asrScore}
          aggScore={aggScore}
          multiple={true}
        />
      )}
      {AppraiserID && <EPPScoreDistribution aggScore={aggScore} />}
    </Box>
  );
}

export default Dashboard;
