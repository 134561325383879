import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Molecule from "../../MComponent/Molecule";
import Atomic from "../../AComponent/Atomic";
import { complexQuery } from "../../Utils/Common/Library";
import { constants } from "./Dimension/Data/QueryConstants";
import { PeriodMap } from "./Dimension/Phases/PeriodMap";

const { Table } = Molecule;
const { Dropdown } = Atomic;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ boxSizing: "border-box", borderRadius: "5px" }}>
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Average Score
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.average}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Rating Label
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.label}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Number Of Employees
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.users.length}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

function PeriodScoreDistribution({ DimensionID, UserID }) {
  const [scoreData, setScoreData] = useState(null);
  const [period, setPeriod] = useState(null);

  const [lineChartSelected, setLineChartSelected] = useState({});
  const palette = useTheme().palette;

  function generateScoreDistribution(epps) {
    const scaleMap = {
      1: "Very Poor",
      2: "Poor",
      3: "Average",
      4: "Good",
      5: "Very Good",
    };

    function findAverage(value) {
      let total = 0;

      for (let i = 0; i < value.length; i++) {
        total += +parseFloat(value[i].PeriodAggregateScore) || 0;
      }

      return +total / value.length || 0;
    }

    const Periods = {};

    let PeriodType = "";
    let defaultPeriodUnit = "";

    epps.forEach((epp,index1) => {
      const assessments = epp.ParameterAssessments?.filter(item=>Object.keys(item).length>0);
      console.log("Check: ", assessments,index1);
      for (let l = 0; l < (assessments?.length || 0); l++) {
        const buckets = {
          0.5: [],
          1: [],
          1.5: [],
          2: [],
          2.5: [],
          3: [],
          3.5: [],
          4: [],
          4.5: [],
          5: [],
        };
        // try {
          const { ReviewPeriodType, ReviewPeriodUnit, PeriodAggregateScore } =
            assessments[l].Data;
        // } catch (e) {
        
        // }
        if (!PeriodType) {
          PeriodType = ReviewPeriodType;
        } else if (PeriodType !== ReviewPeriodType) {
          continue;
        }

        if (!defaultPeriodUnit) {
          defaultPeriodUnit = ReviewPeriodUnit;
        }

        if (!Periods[ReviewPeriodType]) Periods[ReviewPeriodType] = {};
        if (!Periods[ReviewPeriodType][ReviewPeriodUnit])
          Periods[ReviewPeriodType][ReviewPeriodUnit] = { ...buckets };

        const keys = Object.keys(
          Periods[ReviewPeriodType][ReviewPeriodUnit]
        ).sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

        const AggregateScore = PeriodAggregateScore.find(
          (score) => score.ActorType === "Appraiser"
        )?.AggregateScore;
        for (let i = 0; i < keys.length; i++) {
          if (parseFloat(AggregateScore || "0.0000") <= parseFloat(keys[i])) {
            Periods[ReviewPeriodType][ReviewPeriodUnit][keys[i]].push({
              ...epp.EppData,
              ...epp.EppHeader,
              AppraiseeFullName: epp.AppraiseeFullName,
              PeriodAggregateScore: AggregateScore,
            });
            break;
          }
        }
      }
    });

    const PeriodsData = Periods[PeriodType] || {};

    Object.entries(PeriodsData).forEach(([key, value]) => {
      const PeriodUnit = key;

      const data = [];

      Object.entries(value)
        .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
        .forEach(([key, value2]) => {
          const low = parseFloat(key) - 0.5,
            high = parseFloat(key);

          data.push({
            range: `${low} - ${high}`,
            label: scaleMap[low] || scaleMap[high] || "Unrated",
            numEmployees: value2.length,
            users: value2,
            average: findAverage(value2),
          });
        });

      PeriodsData[PeriodUnit] = data;
    });

    return { Periods: PeriodsData, PeriodType, defaultPeriodUnit };
  }

  useEffect(() => {
    if (UserID) {
      complexQuery({
        RequiredFields: [
          {
            AppraiserID: UserID,
          },
          {
            DimensionID: DimensionID,
          },
        ],
        QueryName: constants.FindAssessmentsOfEPPByAppraiserIDAndDimensionID,
      }).then((res) => {
        const data = generateScoreDistribution(res);
        setScoreData(data);
        setPeriod(data.defaultPeriodUnit);

        console.log(res, data, "PERIOD SCORE DISTRIBUTION");
      });
    }
  }, []);

  return !scoreData || !period ? (
    <>Loading ...</>
  ) : (
    <>
      <Typography variant="h2" color={"primary"}>
        Score Distribution
      </Typography>
      <br />
      <Box display={"flex"} height={"80vh"} className="invisble-scroll">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "0em",
          }}
        >
          <Box>
            <Dropdown
              menuItems={[
                ...Object.keys(scoreData.Periods)
                  .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                  .map((key) => {
                    return {
                      label: PeriodMap[scoreData.PeriodType].values[key],
                      value: key,
                    };
                  }),
              ]}
              label={"Select Period"}
              onChange={(val) => {
                setPeriod(val);
              }}
              value={period}
              styles={{
                float: "right",
                margin: "1em 2em 1em 0",
                width: "10em",
              }}
              size={"small"}
            />
            <br />
            <ComposedChart
              width={800}
              height={300}
              data={scoreData ? scoreData.Periods[period] || [] : []}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Rating Scale",
                  position: "insideMiddle",
                  dy: 20,
                }}
                name="Rating Scale"
              />
              <YAxis
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -20,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                type="monotone"
                dataKey="numEmployees"
                fill={`${palette.primary.main}`}
                onClick={(e) => {
                  setLineChartSelected((prevState) => ({
                    ...prevState,
                    Bar: e,
                  }));
                }}
              />
              <Line
                type="monotone"
                dataKey="numEmployees"
                stroke={`${palette.primary.main}`}
                activeDot={{
                  r: 7,
                  onClick: (data, index) => {
                    setLineChartSelected((prevState) => ({
                      ...prevState,
                      Line: index,
                    }));
                  },
                }}
              />
            </ComposedChart>
          </Box>
          {lineChartSelected["Bar"] && (
            <Box>
              <Table
                heading={["Username", "Score"]}
                rows={[
                  ...lineChartSelected["Bar"].payload.users.map((user) => {
                    return [
                      user.AppraiseeFullName || "N/A",
                      user.PeriodAggregateScore,
                    ];
                  }),
                ]}
                pageLimit={5}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PeriodScoreDistribution;
