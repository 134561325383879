import { useState } from "react";
import {
  getData,
  getDateFromEpoch,
  getFullDateFromEpoch,
} from "../Utils/Common/Library";
import FeatureIDs from "../Utils/Common/FeatureIDs";
import { Box, Typography } from "@mui/material";
import Atomic from "../AComponent/Atomic";
import { Settings } from "@mui/icons-material";

const { Dropdown, Button } = Atomic;

function DashboardGenerator({ getGeneratedDashboardData }) {
  const [details, setDetails] = useState({
    DashboardType: -1,
    Plan: -1,
    PlanPeriod: -1,
    PlanPeriodDimension: -1,
    PlanPeriodDimensionAssessment: -1,
  });
  const [info, setInfo] = useState({
    DashboardType: -1,
    Plan: -1,
    PlanPeriod: -1,
    PlanPeriodDimension: -1,
    PlanPeriodDimensionAssessment: -1,
  });

  const [plansList, setPlansList] = useState(null);
  const [data, setData] = useState({
    Plans: null,
    Periods: null,
    Dimensions: null,
    Assessments: null,
  });

  function onPlanSelected(planId, doc) {
    setInfo((prevState) => ({
      ...prevState,
      Plan: doc.PlanName,
    }));
    setDetails((prevState) => ({
      ...prevState,
      Plan: planId,
      PlanPeriod: -1,
      PlanPeriodDimension: -1,
    }));
    setPlansList([]);
    setData((prev) => ({ ...prev, Periods: null, Dimensions: null }));
    getData({
      featureId: FeatureIDs.PerformanceCycleInitiation,
      payload: { "Data.MPPID": planId },
    }).then((res) => {
      if (res?.Data) {
        let arr = res.Data.map((ele) => {
          console.log("Test ele is: ", ele.PlanCycleEndDate);
          let startDate = +ele.PlanCycleStartDate;
          let endDate = +ele.PlanCycleEndDate;
          let planPeriod =
            getFullDateFromEpoch({ epoch: startDate }) +
            "-" +
            getFullDateFromEpoch({ epoch: endDate });
          return {
            label: planPeriod,
            value: ele.TransactionID,
            period: `${new Date(startDate).getFullYear()}-${new Date(endDate)
              .getFullYear()
              ?.toString()
              ?.substring(2, 4)}`,
          };
        });
        console.log("Array is : ", arr);
        setPlansList([...arr]);
      }
    });
  }

  return (
    <Box minWidth={"400px"} maxWidth={"700px"}>
      <Typography variant="h2" color={"primary"}>
        Dashboard Generator
      </Typography>
      <br />
      <Box>
        <Typography variant="form_text1">Dashboard Type</Typography>
        <Dropdown
          menuItems={[
            // { label: "Plan Progress Tracker", value: "PPT" },
            { label: "Plan Summary Report", value: "PSR" },
          ]}
          onChange={(value) => {
            setInfo((prevState) => ({
              ...prevState,
              DashboardType:
                value === "PSR"
                  ? "Plan Summary Report"
                  : "Plan Progress Tracker",
            }));
            setDetails((prevState) => ({
              ...prevState,
              DashboardType: value,
            }));
          }}
          value={details.DashboardType}
          size={"small"}
          styles={{ width: "15em", marginTop: "0.5em" }}
        />
      </Box>
      <br />
      <Box sx={{ display: "flex", gap: "2em", alignItems: "flex-end" }}>
        <Box>
          <Typography variant="form_text1">Plan</Typography>
          <Dropdown
            styles={{ width: "15em", marginTop: "0.5em" }}
            featureId={FeatureIDs.MasterPerformancePlan}
            mapper={{ label: "PlanName", value: "TransactionID" }}
            value={details.Plan}
            onChange={onPlanSelected}
            size={"small"}
            storeApiResult={(data) =>
              setData((prev) => ({ ...prev, Plans: data }))
            }
            apiResult={data.Plans}
          />
        </Box>
        {plansList?.length > 0 && (
          <Box>
            <Typography variant="form_text1">Plan Period</Typography>
            <Dropdown
              styles={{ width: "18em", marginTop: "0.5em" }}
              menuItems={plansList}
              value={details.PlanPeriod}
              size={"small"}
              onChange={(val, doc) => {
                console.log(
                  "valorant",
                  val,
                  plansList.find((p) => p.value === val)?.label
                );
                setInfo((prevState) => ({
                  ...prevState,
                  PlanPeriod: plansList.find((p) => p.value === val)?.label,
                }));
                setDetails((prevState) => ({ ...prevState, PlanPeriod: val }));
              }}
              mapper={{ label: "planPeriod", value: "TransactionID" }}
              storeApiResult={(data) =>
                setData((prev) => ({ ...prev, Periods: data }))
              }
              apiResult={data.Periods}
            />
          </Box>
        )}
      </Box>
      <br />
      <Box sx={{ display: "flex", gap: "2em", alignItems: "flex-end" }}>
        {details.DashboardType === "PPT" &&
          details.PlanPeriod &&
          details.PlanPeriod !== -1 && (
            <Box>
              <Typography variant="form_text1">Dimension</Typography>
              <Dropdown
                styles={{ width: "10em", marginTop: "0.5em" }}
                size={"small"}
                featureId={FeatureIDs.PerformanceDimensionGrouping}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                value={details.PlanPeriodDimension}
                onChange={(val, doc) => {
                  setInfo((prevState) => ({
                    ...prevState,
                    PlanPeriodDimension: doc.DimensionName,
                  }));
                  setDetails((prevState) => ({
                    ...prevState,
                    PlanPeriodDimension: val,
                  }));
                }}
                storeApiResult={(data) =>
                  setData((prev) => ({ ...prev, Dimensions: data }))
                }
                apiResult={data.Dimensions}
              />
            </Box>
          )}
        {details.DashboardType === "PPT" &&
          details.PlanPeriodDimension &&
          details.PlanPeriodDimension !== -1 && (
            <Box>
              <Typography variant="form_text1">Review Period</Typography>
              <Dropdown
                styles={{ width: "10em", marginTop: "0.5em" }}
                size={"small"}
                featureId={FeatureIDs.PerformanceAssessmentInitiation}
                getDataProps={{
                  payload: {
                    "Data.MPPID": details.Plan,
                    "Data.DimensionID": details.PlanPeriodDimension,
                  },
                }}
                mapper={{
                  label: "AssessmentPeriod",
                  value: "TransactionID",
                }}
                value={details.PlanPeriodDimensionAssessment}
                onChange={(val, doc) => {
                  setInfo((prevState) => ({
                    ...prevState,
                    PlanPeriodDimensionAssessment: doc.AssessmentPeriod,
                  }));
                  setDetails((prevState) => ({
                    ...prevState,
                    PlanPeriodDimensionAssessment: val,
                  }));
                }}
                storeApiResult={(data) =>
                  setData((prev) => ({ ...prev, Assessments: data }))
                }
                apiResult={data.Assessments}
              />
            </Box>
          )}
      </Box>
      <br />
      <Button
        label={"Generate"}
        onClick={() => {
          if (details.DashboardType === "PSR") {
            getGeneratedDashboardData(data, plansList, details, info);
          }
        }}
        style={{ textTransform: "none" }}
        otherProps={{ endIcon: <Settings /> }}
      />
    </Box>
  );
}

export default DashboardGenerator;
