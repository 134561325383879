import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  // BrowserRouter as Router
  HashRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import store from "./Components/Redux/Store";
//components

// import Landing from "./Components/Workspace/Landing";
import SecurityWrapper from "./SecurityWrapper";
import List from "./Components/Workspace/TAM/List";
//theme
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "./Components/Utils/CustomHooks/WindowRezise";
// import Themes from "./Components/Testjson/Theme";

// import Settings from "./Components/Workspace/Settings";
// import Message from "./Components/Utils/Messages/Message";
// import PMS from "./Components/NComponent/pms/PMS";
// import TCode from "./Components/Workspace/TCode";
// import Workflow from "./Components/NComponent/Workflow/workflow";
// import MyPlan from "./Components/NComponent/pms/MyPlan";
// import MyTeamPlan from "./Components/NComponent/pms/MyTeamPlan";

// import Settings2 from "./Components/Workspace/Settings2/Settings2";
import PlanInit from "./Components/NComponent/pms/PlanInit";
import Assesment from "./Components/NComponent/pms/Assesment";
import Reports1 from "./Components/NComponent/pms/Reports/report1";
import MainLayout from "./Components/MainLayout";
import PlanProgress from "./Components/NComponent/pms/Reports/PlanProgress";
import MapReports from "./Components/NComponent/pms/Reports/Maps";
import AppraisalReport from "./Components/NComponent/pms/Reports/AppraisalReport";
import PlanSummary from "./Components/NComponent/pms/Reports/PlanSummary";
import Dashboard from "./Components/Dashboards";

const LazyMessage = lazy(() => import("./Components/Utils/Messages/Message"));
const LazyPMS = lazy(() => import("./Components/NComponent/pms/PMS"));
const LazyTCode = lazy(() => import("./Components/Workspace/TCode"));
const LazyWorkflow = lazy(() =>
  import("./Components/NComponent/Workflow/workflow")
);
const LazyDashboard = lazy(() => import("./Components/Dashboards"));
const LazyMyPlan = lazy(() => import("./Components/NComponent/pms/MyPlan"));
const LazyMyTeamPlan = lazy(() =>
  import("./Components/NComponent/pms/MyTeamPlan")
);
const HrView = lazy(() => import("./Components/NComponent/pms/HrView"));
const LazySettings2 = lazy(() =>
  import("./Components/Workspace/Settings2/Settings2")
);
// const LazyPlanInit = lazy(() => import("./Components/NComponent/pms/PlanInit"));
// const LazyAssesment = lazy(() =>
//   import("./Components/NComponent/pms/Assesment")
// );
const LazyLanding = lazy(() => import("./Components/Workspace/Landing"));
//const LazyMainLayout = lazy(() => import('./Components/MainLayout'));

const LoadingFallback = () => <div>Loading...</div>;
const routes = [
  // { path: "/message", component: LazyMessage },
  { path: "/pms", component: LazyPMS },
  { path: "/tcode", component: LazyTCode },
  { path: "/workflow", component: LazyWorkflow },
  { path: "/myplan", component: LazyMyPlan },
  { path: "/myteamplan", component: LazyMyTeamPlan },
  { path: "/hrview", component: HrView },
  { path: "/settings2", component: LazySettings2 },
  // { path: "/planinit", component: LazyPlanInit },
  // { path: "/assesment", component: LazyAssesment },
  { path: "/", component: LazyLanding },
  { path: "/dashboard", component: LazyDashboard },
];
const tenantId = "1644570790934";
// keycloak.init({onLoad: 'login-required'})
// const key=window.Keycloak(Data)
// const Keycloak = window.Keycloak(Data);
// console.log("From auth ",Keycloak.logout())
const mapper = {
  PlanInit: PlanInit,
  Assessment: Assesment,
  Reports: Reports1,
  PlanProgress: PlanProgress,
  MapReports,
  AppraisalReport,
  PlanSummary,
  AppraisalReportAppraiserView: ({ onClose }) => (
    <AppraisalReport onClose={onClose} isAppraiser={true} />
  ),
  PlanProgressAppraiserView: ({ onClose }) => (
    <PlanProgress onClose={onClose} isAppraiser={true} />
  ),
  PlanSummaryAppraiserView: ({ onClose }) => (
    <PlanSummary onClose={onClose} isAppraiser={true} />
  ),
  HrDashboard: ({ onClose }) => (
    <Dashboard onClose={onClose} isAppraiser={false} />
  ),
  AppraiserDashboard: ({ onClose }) => (
    <Dashboard onClose={onClose} isAppraiser={true} />
  ),
};

function setZoomLevel(width) {
  var zoomLevel = width / 1500;
  document.documentElement.style.zoom = zoomLevel;
}
function App({ id, tk, layout_id, debug, tenantCode }) {
  const [authenticated, setAuthenticated] = useState(null);
  //  if(!document.cookie || document.cookie==='null'){
  //   window.location.href="http://localhost:3001";
  //  }
  // const key=Keycloak(Data);
  const { width } = useWindowDimensions();
  const Token = useSelector((state) => state.AuthReducer.Token);
  const SideComponent = useSelector((state) => state.SideNavReducer.Component);
  // const TenantCode=useSelector((state)=>state.AuthReducer.tenantCode)
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("id");
  console.log("From app id: ", c);
  let history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setZoomLevel(width);
  }, [width]);
  useEffect(() => {
    const url = new URL(window.location.href);
    const host = url.host;

    if (url.href.includes("?")) {
      console.log("Before url: ", url.href, " ", history);
      //   history.push("/");
    }
  }, [history]);

  const layout = useSelector((state) => {
    console.log("From Reducer ", state);
    return state.LayoutReducer.Layout;
  });
  const [theme, setTheme] = React.useState("lightTheme");
  const [Component, setComponent] = React.useState(null);
  const [messageList, setMsgList] = React.useState([]);

  const Comp = mapper[Component];
  const isTam = store.getState().AuthReducer.isTam;
  function renderApp() {
    return (
      <div className="body">
        <SecurityWrapper>
          <Router>
            {/* <Header  /> */}
            {/* <SideNav setComponent={setComponent} /> */}
            {JSON.stringify(window.process)}
            {!process.browser && (
              <div>
                <input id="route" />
                <button
                  onClick={() => {
                    let val = document.getElementById("route");
                    if (!val) return;
                    history.push(val);
                  }}
                />
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Suspense fallback={<LoadingFallback />}>
                <LazyMessage />
              </Suspense>
              {/* <Message /> */}
              {/* <TwoFactor /> */}
            </div>

            {Comp && <Comp onClose={() => setComponent(null)} />}

            {/* routing between different workspaces */}
            <div onClick={() => {}}>
              {/* <Launcher
        agentProfile={{
          teamName: 'chat-window',
          imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
        }}
        onMessageWasSent={_onMessageWasSent}
      
        messageList={messageList}
        showEmoji
      
      /> */}
            </div>
            <MainLayout setComponent={setComponent}>
              {!isTam ? (
                <div>
                  <Switch>
                    {/* <Route
                      render={(props) => (
                        <Landing {...props} tk={tk} layout_id={layout_id} />
                      )}
                      exact
                      path="/"
                    /> */}
                    <Suspense fallback={<LoadingFallback />}>
                      {routes.map((route) => (
                        <Route
                          exact
                          path={route.path}
                          component={route.component}
                          key={route.path}
                        />
                      ))}
                    </Suspense>
                    {/* <Route exact path='/' component={Landing}/> */}

                    {/* <Route exact path="/Dashboard" component={Dashboard} /> */}

                    {/* <Route exact path="/Tree" component={Tree2} />
                <Route exact path="/Excel" component={ReactExcel} />
                <Route exact path="/Excel2" component={Excel2} /> */}
                    {/* <Route exact path="/Editor" component={Editor} /> */}
                    {/* <Route exact path="/Editor2" component={Editor2} /> */}
                    {/* <Route exact path="/Layout" component={Layout} />
                <Route exact path="/Grid" component={AGgrid} /> */}
                    {/* <Route exact path="/Settings" component={Settings} /> */}
                    {/* <Route exact path="/Settings2" component={Settings2} /> */}

                    {/* <Route exact path="/test" component={Test} /> */}
                    {/* <Route
                      exact
                      path="/LayoutUpload"
                      component={LayoutUpload}
                    /> */}
                    {/* <Route exact path="/chat" component={Chat} /> */}

                    {/* <Route exact path="/pms" component={PMS} /> */}

                    {/* <Route exact path="/bulk" component={BulkUploader} />
                    <Route exact path="/formBuilder" component={FormBuilder} /> */}

                    {/* <Route exact path="/workflow" component={Workflow} />
                    <Route exact path="/myplan" component={MyPlan} />
                    <Route exact path="/myteamplan" component={MyTeamPlan} />
                    <Route exact path="/tcode" component={TCode} />
                    <Route exact path="/tam" component={List} /> */}

                    {/* <Route exact path="/dashboard" component={Dashboard} /> */}
                  </Switch>
                </div>
              ) : (
                <List />
              )}
            </MainLayout>
          </Router>
          {/* <br /> <br /> <br /> */}
          {/* <Footer/> */}
        </SecurityWrapper>
      </div>
    );
  }

  return (
    //  <ReactKeycloakProvider onAuthError={(e)=>console.log("Key error: ",e)} onEvent={(event,error)=>console.log("From Keyclock1 :",event," ",error)}  authClient={keycloak} initOptions={{onLoad: 'login-required'}} onTokens={(res)=>{onToken(res.token)}} >

    <div className="App" style={{ position: "relative" }}>
      {console.log("Token is: ", tenantCode, " ", tenantId, " ", Token)}
      {(Token || tenantCode || tenantId) && renderApp()}
    </div>
    //  </ReactKeycloakProvider>
  );
}

export default App;
