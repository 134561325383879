// import * as XLSX from "xlsx";
import Gateway from "../Api/Gateway";
import store from "../../Redux/Store";
import URLS from "../index";
import { Actions } from "../../Redux/Actions/Actiontypes";
import Cookies from "js-cookie";
import axios from "axios";
import FeatureIDs from "./FeatureIDs";

const {
  TRANSACTION_WRITER_URL,
  FUNCTION_HANDLER_URL,
  APPLICABILITY_URL,
  COMPLEX_QUERY,
  AUTH_URL,
  TAM_URL,
  DATA_ANALYTICS,
  LOGGER_URL,
  APPRAISER_REPORT,
} = URLS;

const header = {
  EffectiveFromTimestamp: "",
  FeatureID: "",
  FeatureVariantID: "000",
  UserId: "",
  TransactionID: "",
  StepId: "", //
  ModuleId: "",
  Status: "1",
  RequestType: "0",
  EffectiveTillTimestamp: "",
  ActionType: "", //
};

const validationType = {
  0: "Required",
  1: "AlphaNumeric",
};
export const getTenantCode = () => {
  return store.getState().AuthReducer?.tenantCode;
};
export const validatePassword = (options, password) => {
  const errors = [];

  // Minimum password length
  const minLength = parseInt(options.MinimumPasswordLength, 10);
  if (isNaN(minLength) || minLength <= 0) {
    errors.push("Invalid minimum password length.");
  } else if (password.length < minLength) {
    errors.push(`Password should be at least ${minLength} characters long.`);
  }

  // Maximum password length
  const maxLength = parseInt(options.MaximumPasswordLength, 10);
  if (isNaN(maxLength) || maxLength <= 0) {
    errors.push("Invalid maximum password length.");
  } else if (maxLength < password.length) {
    errors.push(`Password should not exceed ${maxLength} characters.`);
  }

  // Lowercase
  if (options.Lowercase === "1" && !/[a-z]/.test(password)) {
    errors.push("Password should contain at least one lowercase letter.");
  }

  // Uppercase
  if (options.Uppercase === "1" && !/[A-Z]/.test(password)) {
    errors.push("Password should contain at least one uppercase letter.");
  }

  // Digits
  if (options.Digits === "1" && !/\d/.test(password)) {
    errors.push("Password should contain at least one digit.");
  }

  // Special characters
  if (options.SpecialCharacters === "1" && !/\W/.test(password)) {
    errors.push("Password should contain at least one special character.");
  }

  if (errors.length === 0) {
    return { success: true };
  } else {
    return { error: errors };
  }
};
export const checkReportFile = (
  props = {
    reportName: "",
  }
) => {
  const { reportName } = props;
  const tenantCode = getTenantCode();
  const params = {
    queryName: reportName,
    tenantCode,
  };
  const endpoint = "/getReport";
  return Gateway({
    url: DATA_ANALYTICS + endpoint,
    params,
    type: "GET",
  });
};
export const generateReport = (
  props = {
    queryName: null,
    requiredFields: [],
    toJSON: false,
  }
) => {
  const { queryName, requiredFields, AppraiserUserID, toJSON } = props;
  const tenantCode = getTenantCode();
  if (!requiredFields) requiredFields = [];
  let payload = {
    QueryName: queryName,
    RequiredFields: [{ TenantCode: tenantCode }, ...requiredFields],
  };
  let url = DATA_ANALYTICS + "/generateReport";

  if (toJSON) {
    url = url + "?responseMode=JSON";
  }

  if (AppraiserUserID) {
    payload.AppraiserUserID = AppraiserUserID;
    // url = DATA_ANALYTICS + "/generateReportBy/Appraiser";
  }

  console.log(
    {
      // url: DATA_ANALYTICS + "/generateReport",
      //  url: DATA_ANALYTICS + "/generateReport/Appraiser",
      url,
      payload,
      displayError: 1,
    },
    "GENERATEDPAYLOADISHERE"
  );

  return Gateway({
    // url: DATA_ANALYTICS + "/generateReport",
    //  url: DATA_ANALYTICS + "/generateReport/Appraiser",
    url,
    payload,
    displayError: 1,
  });
};
export const getAuthDataFromStore = (
  props = {
    fields: [],
  }
) => {
  const { fields } = props;
  let data = store.getState().AuthReducer;
  return {
    token: data.Token,
    tenantCode: data.tenantCode,
    userId: data.userId,
    subDomain: data.subDomain,
  };
};
export const getFiles = (
  props = {
    queryName: null,
    size: 5,
    isAppraiser: false,
  }
) => {
  let { queryName, size, isAppraiser } = props;
  if (!queryName) return;
  if (!size) size = 5;
  const tenantCode = getTenantCode();
  const params = {
    queryName,
    maxSize: size,
    tenantCode,
  };
  if (isAppraiser === true) {
    params.appraiserUserId = getUserId();
  }
  return Gateway({
    url: DATA_ANALYTICS + "/getReportsList",
    params,
    type: "GET",
  });
};

export const serverLog = (data) => {
  if (typeof data !== "string") data = JSON.stringify(data);
  return new Promise((resolve, reject) => {
    let payload = {
      message: data,
      tenantCode: getTenantCode(),
      userId: getUserId(),
    };
    axios
      .post(LOGGER_URL + "/log", payload, {
        headers: {
          Authorization: `Bearer ${store.getState().AuthReducer.Token}`,
        },
      })
      .then((res) => resolve(res))
      .catch((e) => {
        console.log("Error: ", e);
        reject(e);
      });
  });
};
export const deleteTokenFromCookies = () => {
  let cookieOptions;
  let newPage = null;
  let host = new URL(window.location.href).host;
  let subDomain = Cookies.get("subDomain") || "www";

  if (host.includes("chipsoft")) {
    cookieOptions = {
      domain: ".chipsoft.in",
    };
    newPage = `https://${subDomain}.chipsoft.in`;
  } else if (host.includes("myemploywise")) {
    cookieOptions = {
      domain: ".myemploywise.com",
    };
    newPage = `https://${subDomain}.myemploywise.com`;
  }
  Cookies.remove("EwAccessToken", cookieOptions);
  Cookies.remove("EwRefreshToken", cookieOptions);
  Cookies.remove("idToken");
  Cookies.remove("subDomain", cookieOptions);
  if (subDomain === "e-emphasys") {
    window.location.href = "https://e-emphasys.com/";
    return;
  }
  if (newPage) window.location.href = newPage;
  window.history.replaceState({}, "", "/");
  window.location.reload();
};
export const performLogout = (props = { byPassCheck: false }) => {
  const { byPassCheck } = props;
  sessionStorage.removeItem("EPP_NAV_DATA");
  // function deleteTokenFromCookies() {
  //   let cookieOptions;
  //   let newPage = null;
  //   let host = new URL(window.location.href).host;
  //   let subDomain = Cookies.get("subDomain") || "www";
  //   if (host.includes("chipsoft")) {
  //     cookieOptions = {
  //       domain: ".chipsoft.in",
  //     };
  //     newPage = `https://${subDomain}.chipsoft.in`;
  //   } else if (host.includes("myemploywise")) {
  //     cookieOptions = {
  //       domain: ".myemploywise.com",
  //     };
  //     newPage = `https://${subDomain}.myemploywise.com`;
  //   }
  //   Cookies.remove("EwAccessToken", cookieOptions);
  //   Cookies.remove("EwRefreshToken", cookieOptions);
  //   Cookies.remove("idToken");
  //   Cookies.remove("subDomain", cookieOptions);
  //   if (newPage) window.location.href = newPage;
  //   window.location.reload();
  // }
  if (byPassCheck) {
    deleteTokenFromCookies();
    return;
  }
  let token = Cookies.get("EwAccessToken");
  let { subDomain } = getAuthDataFromStore();
  if (!subDomain || subDomain === "") {
    subDomain = Cookies.get("subDomain");
  }
  let payload = {
    Token: token,
    SubDomain: subDomain,
    Mode: "LG",
  };
  Gateway({
    url: AUTH_URL,
    payload,
  }).then((res) => {
    console.log("Logout result is : ", res);
    if (res.Message) {
      deleteTokenFromCookies();
    }
  });
};
export const setTokens = (
  props = { accessToken: null, refreshToken: null }
) => {
  const { accessToken, refreshToken } = props;
  console.log("URLS: 3 ", props);
  Cookies.set("EwAccessToken", accessToken);
  Cookies.set("EwRefreshToken", refreshToken);
};
export const getAuthDocument = (
  props = {
    subDomain: null,
  }
) => {
  const { subDomain } = props;
  if (!subDomain) return;
  let payload = {
    SubDomain: subDomain,
    Mode: "GD",
    PasswordPolicy: "Y",
  };
  return Gateway({
    url: AUTH_URL,
    payload,
  });
};
export const getTokenFromCode = (
  props = {
    code: null,
    subDomain: null,
  }
) => {
  const { code, subDomain } = props;
  if (!code) return null;
  let payload = {
    AuthorisationCode: code,
    SubDomain: subDomain,
    Mode: "GT",
  };
  return Gateway({
    url: AUTH_URL,
    payload,
  });
};
export const getActions = () => {
  return Actions;
};

export const tamLogin = (
  props = {
    username: "",
    password: "",
  }
) => {
  const { username, password } = props;
  let payload = {
    username: username,
    password: password,
  };
  return Gateway({
    url: TAM_URL + "face-recognition/login/health",
    payload,
  });
};

export const performLogin = (
  props = {
    name: null,
    password: null,
    subDomain: null,
    authCode: null,
    mode: null,
  }
) => {
  let { name, password, subDomain, authCode, mode } = props;
  if (!mode) mode = "LU";
  let payload = {
    UserLoginName: name,
    Password: password,
    SubDomain: subDomain,
    Mode: mode,
    AuthorisationCode: "",
  };
  if (authCode) {
    payload.AuthorisationCode = authCode;
    payload.Mode = "SSO";
  }
  return Gateway({
    url: AUTH_URL,
    payload,
  });
};
export const performChangePassword = (
  props = {
    userName: "",
    password: "",
    oldPassword: "",
    subDomain: "",
    uniqueId: "",
  }
) => {
  const { userName, password, subDomain, oldPassword, uniqueId } = props;
  let payload;
  if (uniqueId) {
    payload = {
      UserLoginName: uniqueId,
      NewPassword: password,
      SubDomain: subDomain,
      Mode: "SP",
    };
  } else {
    payload = {
      UserLoginName: userName,
      NewPassword: password,
      OldPassword: oldPassword,
      SubDomain: subDomain,
      Mode: "CP",
    };
  }

  return Gateway({
    url: AUTH_URL,
    payload,
  });
};
export const clearAllValues = (
  props = {
    id: null,
    fromLayoutJson: null,
  }
) => {
  let { id, fromLayoutJson } = props;
  if (fromLayoutJson) id = fromLayoutJson.id;
  if (!id) return;
  let data = store.getState().DataReducer[id];
  Object.entries(data).forEach(([key, value]) => {
    if (key !== "otherData" && value.value) {
      data[key].value = "";
    }
  });
  store.dispatch({
    type: Actions.UPDATE_ALL_FIELDS,
    payload: {
      data,
      id,
    },
  });
};
export const updateGroups = (
  props = {
    type: 2, //1->add group,2-> delete group, 3-> replace group array
    group: null, // single group
    id: null,
    groups: [], // array of groups
    fromLayoutJson: null,
    clear: false,
  }
) => {
  let { type, group, id, groups, fromLayoutJson, clear } = props;

  if (!groups && !group) return;
  if (fromLayoutJson) id = fromLayoutJson.id;
  if (!groups) groups = [group];
  if (!type) type = 3;
  let data = store.getState().DataReducer[id]?.otherData?.enabledGroups;
  if (!data) data = [];
  if (type === 3) data = groups;
  if (type === 2) {
    data = [...data, ...groups];
  }
  if (type === 1) {
    //delete
    data = data.filter((item) => !groups.includes(item));
  }
  store.dispatch({
    type: Actions.ADD_ENABLED_GROUPS,
    payload: {
      id,
      enabledGroups: data,
    },
  });
  if (clear) clearAllValues({ id });
};
export const getFeatureDocument = (
  props = {
    FeatureID: null,
  }
) => {
  const { FeatureID } = props;
  const TenantCode = store.getState().AuthReducer?.tenantCode;
  let payload = {
    function: "getFetauredoc",
    FeatureVariantID: "000",
    FeatureID,
    TenantCode,
  };
  return Gateway({
    url: FUNCTION_HANDLER_URL,
    payload,
  });
};

export const deactivateDocument = (
  props = {
    id: null,
    FeatureID: null,
  }
) => {
  const { id, FeatureID } = props;
  let payload = {
    ...header,
    TransactionID: id,
    EffectiveFromTimestamp: "-1",
    RequestType: "2",
    FeatureID,
  };
  return Gateway({
    url: TRANSACTION_WRITER_URL,
    payload,
  });
};

export const complexQuery = (
  props = {
    RequiredFields: null,
    QueryName: null,
    featureId: null,
    otherData: {},
  }
) => {
  let { RequiredFields, featureId, otherData, QueryName } = props;
  if (!otherData) otherData = {};
  if (!RequiredFields) RequiredFields = [];
  const TenantCode = store.getState().AuthReducer?.tenantCode;
  const defaultFields = [{ TenantCode }];

  const payload = {
    RequiredFields: [...defaultFields, ...RequiredFields],
    QueryName,
    ...otherData,
  };

  return Gateway({
    url: COMPLEX_QUERY,
    payload: payload,
    tenantCode: false,
  });
};

export const getData = (
  props = {
    fields: null, //[Header.something,Data.Something,Data.*]
    featureId: null,
    payload: null, //{key:value}
    status: null,
  }
) => {
  let { fields, featureId, payload, FeatureVariantID, status } = props;
  console.log("Get data props: ", props);
  if (!featureId) return;
  if (!FeatureVariantID) FeatureVariantID = "000";
  if (!status) status = "1";
  const TenantCode = store.getState().AuthReducer?.tenantCode;
  console.log("Insdie :", store.getState(), " ", TenantCode);
  let fieldsQuery = "{Header.*,Data.*}";
  let mapper = "*";
  let payloadStr = "{}";
  if (fields?.length > 0) {
    fieldsQuery = "{" + fields.join(",") + "}";
  }
  if (payload && Object.keys(payload).length > 0) {
    payloadStr = "{";
    mapper = "";
    Object.entries(payload).map(([key, value]) => {
      payloadStr += key.split(".")[1] + ":" + value + ",";
      mapper += key + `=@${key.split(".")[1]}@,`;
    });
    payloadStr += "}";
  }
  let query = `({${TenantCode}}!{${featureId},${FeatureVariantID},*,${status},*,*}!{${mapper}}!${fieldsQuery})`;
  let queryPayload = {
    function: "getData",
    expression: query,
    payload: payloadStr,
  };
  return Gateway({
    url: FUNCTION_HANDLER_URL,
    payload: queryPayload,
  });
};

export const getFullDateFromEpoch = (
  props = {
    epoch: null,
    time: false,
  }
) => {
  const { epoch, time } = props;
  console.log("Epoch: ", epoch);
  let date = new Date(+epoch);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [day, month, year] = [
    date.getDate(),
    months[date.getMonth()],
    date.getFullYear(),
  ];
  if (time) {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = date.toLocaleTimeString(undefined, options);
    return `${month} ${day},${year} ${formattedTime}`;
  }
  return `${month} ${day},${year}`;
};
export const getDateFromEpoch = (
  props = {
    epoch: null,
    includeTime: null,
    returnNthString: false,
    format: null, //1-> yyyy-mm-dd
  }
) => {
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  }
  const { epoch, includeTime, returnNthString, format } = props;
  let date = new Date(+epoch);
  let options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };
  if (includeTime) {
    options = { ...options, hour: "numeric", minute: "numeric", hour12: true };
  }
  if (format === 1) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  let formattedDate = date.toLocaleDateString("en-US", options);
  // if (includeTime)
  //   formattedDate = date.toLocaleString("en-US", {
  //     month: "short",
  //     day: "numeric",
  //     year: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //     timeZone: "Asia/Kolkata",
  //   });
  if (returnNthString) {
    try {
      const [month, day, year] = formattedDate.split(" ");
      const dayWithSuffix = getDayWithSuffix(parseInt(day.replace(",", "")));
      formattedDate = `${dayWithSuffix} ${month} ${year}`;
    } catch (e) {
      console.log("Conversion error ", e);
    }
  }
  return formattedDate;
};
export const SaveApplicability = (
  props = {
    payload: null,
    editMode: null,
    ApplicabilityReferenceField: "",
  }
) => {
  let { payload, editMode, ApplicabilityReferenceField } = props;
  if (!ApplicabilityReferenceField) return;
  let action = "0";
  if (editMode) action = "1";
  if (!editMode) editMode = {};
  var TenantCode = store.getState().AuthReducer?.tenantCode;

  let appPayload = {
    Action: action,
    Payload: {
      Header: {
        DocumentID: "",
        DocumentType: "3",
        FeatureID: "00103",
        FeatureVariantID: "002",
        Version: "000",
        TransactionID: "",
        CreationTimestamp: "",
        EffectiveFromTimestamp: "",
        EffectiveTillTimestamp: "",
        Status: "1",
        FeatureID: "00103",
        TenantCode,
        ...editMode,
      },
      Data: payload,
    },
  };
  appPayload.ApplicabilityReferenceField = ApplicabilityReferenceField;
  return Gateway({ url: APPLICABILITY_URL, payload: appPayload });
};

export const handleResult = (res) => {
  const { Message } = res;
  console.log("Message : workflwo", Message);
  if (Message === "Document inserted") {
    //Document inserted
    return 1;
  } else if (Message === "Document Edited") {
    return 2;
  } else {
    return -1;
  }
};

export const getTree = (
  props = {
    payload: null,
  }
) => {
  let { payload } = props;
  const TenantCode = store.getState().AuthReducer?.tenantCode;
  payload.TenantID = TenantCode;
  return Gateway({
    url: FUNCTION_HANDLER_URL,
    payload,
  });
};
export const getUserId = () => {
  return store.getState().AuthReducer.userId;
};
function validate(arr, state, field) {
  for (let i = 0; i < arr.length; i++) {
    const { type, error } = arr[i];
    if (type === 0) {
      let val = state[field];
      if (val === undefined || val === null || (val && val.trim(" ") === ""))
        return { error };
    }
  }
}
export const createAndValidatePayload = (fieldObject, state) => {
  if (!fieldObject) return;
  let payload = {};
  for (const [key, value] of Object.entries(fieldObject)) {
    if (value.validations) {
      let validationResult = validate(value.validations, state, key);
      if (validationResult.error) return validationResult;
    }
    payload[key] = state[key];
  }
  return { success: true, payload };
};
export const submitPayload = (
  props = {
    featureId: null,
    payload: null,
    edit: null,
    fromLayoutJson: null,
    updateAction: null,
    headerSection: null,
  }
) => {
  let {
    featureId,
    payload,
    edit,
    fromLayoutJson,
    updateAction,
    headerSection,
  } = props;
  try {
    if (
      featureId === FeatureIDs.ParameterAndTargets ||
      featureId === FeatureIDs.ParameterAsseessment
    ) {
      serverLog(props);
    }
  } catch (e) {
    console.log("Error: ", e);
  }
  //If Payload is sent by layout json
  if (fromLayoutJson) {
    const { id } = fromLayoutJson;
    const editMode = store.getState().DataReducer[id]?.otherData?.editMode;
    if (editMode) {
      edit = editMode;
    }
    if (!id) return;
    let data = store.getState().DataReducer[id];
    if (!data) return;
    Object.entries(payload).forEach(([key, value]) => {
      if (value.charAt(0) === "#") {
        value = value.substring(1, value.length);
        if (value.includes(".")) {
        } else {
          payload[key] = data[value]?.value ? data[value].value : "";
        }
      }
    });
  }
  if (!headerSection) headerSection = {};
  let stepHeader = {};
  if (updateAction) {
    const { stepId, action, WorkflowID } = updateAction;
    stepHeader = {
      StepId: stepId,
      ActionType: action,
      WorkflowID,
    };
  }
  console.log("Library: 1", updateAction);
  let sendObj = {
    ...header,
    ...stepHeader,
    ...headerSection,
    FeatureID: featureId,
    Payload: payload,
  };
  if (edit && edit.TransactionID && edit.TransactionID.trim(" ") !== "") {
    sendObj = {
      ...sendObj,
      ...edit,
      TransactionID: edit.TransactionID,
      RequestType: "1",
    };
  }

  console.log("Library: ", sendObj);
  return Gateway({
    payload: sendObj,
    url: TRANSACTION_WRITER_URL,
  });
};
export const getFileSizeJSON = (props) => {
  let { json } = props;
  let jsonBlob = new Blob([JSON.stringify(json)], { type: "application/json" });
  let jsonSizeInBytes = jsonBlob.size;
  let jsonSizeInMB = jsonSizeInBytes / (1024 * 1024);
  return jsonSizeInMB;
};

export const convertRawToTable = (props) => {
  const { raw } = props;

  if (!raw || raw.trim(" ") === "") return [];
  var lines = raw.split("\n");
  for (let i = 0; i < lines.length; i++) {
    lines[i] = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
  }
  return lines;
};

export const csvToRaw = async (props) => {
  return {};
  // const XLSX = await import("xlsx");
  // const { file, cb, raw } = props;
  // const reader = new FileReader();
  // reader.onload = (evt) => {
  //   const bstr = evt.target.result;
  //   const wb = XLSX.read(bstr, { type: "binary" });
  //   const wsname = wb.SheetNames[0];
  //   const ws = wb.Sheets[wsname];
  //   const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
  //   //  console.log("Data>>>" + data);
  //   if (!raw) {
  //     let table = convertRawToTable({ raw: data });
  //     console.log("Table: ", cb);
  //     cb(table);
  //   } else {
  //     cb(data);
  //   }
  // };
  // reader.readAsBinaryString(file);
};
