import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  extractAddRules,
  extractDimensionStructure,
  extractDiscardRules,
  extractTargetRules,
  extractWeightRules,
  isDisabled,
  isNA,
  isPermitted,
  isRestricted,
  isUserDefined,
} from "./Helpers/helper";
import AddGroup from "./Modals/AddGroup";
import Molecule from "../../../MComponent/Molecule";
import DeleteAlert from "./Modals/DeleteAlert";
import GoalSetting from "./Phases/GoalSetting";
import Assessment from "./Phases/Assessment";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import {
  getData,
  getUserId,
  submitPayload,
  getDateFromEpoch,
} from "../../../Utils/Common/Library";
import ListView from "./ListView";
import PhaseAccordian from "./PhaseAccordian";
import { PeriodMap, PeriodMapper, getFinalPeriod } from "./Phases/PeriodMap";
import Atomic from "../../../AComponent/Atomic";
import { WorkflowProcesser } from "./WorkflowProcesser";
import { useDispatch } from "react-redux";
import { Actions } from "../../../Redux/Actions/Actiontypes";
import SidePanel from "../../../SidePanel";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import ActionDebouncer from "./ActionDebouncer";

const { EwModal } = Molecule;
const { Button } = Atomic;

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

function dateGenerator(ms) {
  return getDateFromEpoch({ epoch: ms, returnNthString: true });
}

function SubmitAlert({ message, closeAlert, onSubmit, onCancel }) {
  return (
    <Box sx={{ padding: "0.5em" }}>
      <Typography variant="h6" fontWeight={"400"} width={"30vw"}>
        {message}
        {/* <b>{name}</b> */}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2em",
        }}
      >
        <Button
          color={"info"}
          variant={"text"}
          title={"Cancel"}
          onClick={() => {
            closeAlert();
            onCancel();
          }}
        />
        <Button
          color={"info"}
          variant={"contained"}
          style={{ marginLeft: "1em" }}
          title={"Continue"}
          onClick={() => {
            onSubmit();
            closeAlert();
          }}
        />
      </Box>
    </Box>
  );
}

function ParameterChildComponent({
  dimensionId,
  parameter,
  targetEditing,
  payloadData,
  dimensionPhase,
  saveGoalSettingTargets,
  saveParameterAssessments,
  actorAssessment,
  handleTabEdit,
  collapse,
  onChangeAssessment,
}) {
  const EPPData = payloadData.EmployeePerformancePlanData;
  const MapData = payloadData.ParameterMapData;

  let ActionStatusLogs = null;

  if (EPPData) {
    ActionStatusLogs = (EPPData?.PlanStatus || []).filter(
      (plan) => plan.DimensionID === dimensionId
    );
  }
  console.log("Dim perios: 1", ActionStatusLogs);

  let TargetType = null,
    TargetDataType = null,
    AchievementDataType = null,
    TargetsEnabled = "E",
    TargetsMapping = null,
    MappedTargets = null,
    TargetEditing = targetEditing || "D",
    Achievements = "D";

  if (parameter.TargetDataType) {
    TargetType = "FV";
    TargetDataType = AchievementDataType = parameter.TargetDataType;
    TargetsMapping = "U";
  } else {
    const ParameterMapDetails = MapData.Parameters.find(
      (p) => p.ParameterID === parameter.ParameterID
    );
    console.log("Target is: ", MapData);

    if (!ParameterMapDetails) {
      TargetsEnabled = "D";
    } else {
      if (ParameterMapDetails.Achievements === "E") Achievements = "E";
      if (ParameterMapDetails.TargetRule[0] === "D") {
        TargetsEnabled = "D";
      } else {
        TargetsMapping = extractTargetRules(
          ParameterMapDetails.TargetRule
        ).mapped;
        TargetType = extractTargetRules(
          ParameterMapDetails.TargetRule
        ).targetType;
        TargetDataType = ParameterMapDetails.ParameterTargetDataType;
        AchievementDataType = ParameterMapDetails.AchievementsDataType;
        MappedTargets = ParameterMapDetails.ParameterTargets;
      }
    }
  }

  const AssessmentDocuments = [
    ...(payloadData?.ParameterAssessments || []),
  ]?.filter((item) => item);

  if (AssessmentDocuments.length > 0) {
    dimensionPhase = "A";
  } else {
    dimensionPhase = "G";
  }

  const TargetPeriods = parameter.Targets;
  const { SecondGroupWeightRule, ParameterWeightRule, FirstGroupWeightRule } =
    MapData;
  const TargetsActionStatus = ActionStatusLogs?.find(
    (log) =>
      log.TransactionDocumentID === payloadData.ParameterAndTargetDocumentID
  );
  // console.log("Dim perios: ",TargetsActionStatus," ",ParameterAndTargetDocumentID)
  const TargetsActionStatusLog = TargetsActionStatus?.ActionStatus;
  /** Commented this on 19-08-24 */
  // if (!TargetsActionStatusLog) {
  //   return <>No Data</>;
  // }
  console.log("THIS IS THE CURRENT PARAMETER", parameter);

  return (
    <>
      {dimensionPhase === "G" && (
        <GoalSetting
          ParameterTargets={parameter.Targets}
          MappedTargets={MappedTargets}
          TargetsEnabled={TargetsEnabled}
          TargetEditing={TargetEditing}
          TargetType={TargetType}
          TargetMapping={TargetsMapping}
          TargetDataType={TargetDataType}
          RatingScaleID={MapData.RatingScaleID}
          onSave={(updatedTargets) =>
            saveGoalSettingTargets(updatedTargets, parameter.ParameterID)
          }
          ActionStatusLog={TargetsActionStatusLog || ""}
          Workflow={payloadData.Workflow}
        />
      )}
      {dimensionPhase === "A" &&
        TargetPeriods.map((period) => {
          //  const {Header,Workflow,Data}=
          let AssessmentDocument = AssessmentDocuments.filter(
            ({ Data }) => Data.ReviewPeriodUnit === period.TargetPeriodValue
          );

          console.log(
            AssessmentDocument,
            period.TargetPeriodValue,
            "ASSESSMENTDOCUMENT"
          );

          if (
            AssessmentDocument.length > 1 ||
            (AssessmentDocument.length === 1 &&
              AssessmentDocument[0].Data.ReviewPeriodType === "I")
          ) {
            return (
              <PhaseAccordian
                expandable={true}
                title={
                  PeriodMap[PeriodMapper(period.TargetPeriodValue)].values[
                    period.TargetPeriodValue
                  ]
                }
                caption={`${dateGenerator(
                  period.TargetFromDate
                )} - ${dateGenerator(period.TargetTillDate)}`}
              >
                {AssessmentDocument.map((doc) => {
                  const ParameterAssessment = doc.Data.Parameters.find(
                    (p) =>
                      p.ParameterID ===
                      (parameter.ParameterID || parameter.TransactionID)
                  );
                  console.log("Assesment Status: ", ParameterAssessment);
                  // const AssessmentActionStatus = ActionStatusLogs.find(
                  //   (log) => log.TransactionDocumentID === doc.Header.DocumentID
                  // );

                  // if (
                  //   !AssessmentActionStatus ||
                  //   !AssessmentActionStatus.ActionStatus
                  // ) {
                  //   return (
                  //     <div className="Flex">
                  //       <Typography variant="body1" fontWeight={500}>
                  //         Invalid Assessment
                  //       </Typography>
                  //     </div>
                  //   );
                  // }
                  // const AssessmentActionStatusLog =
                  //   AssessmentActionStatus.ActionStatus;
                  // [
                  //   AssessmentActionStatus.ActionStatus.length - 1
                  // ];

                  // if (AssessmentActionStatusLog.length !== 3) {
                  //   return (
                  //     <div className="Flex">
                  //       <Typography variant="body1" fontWeight={500}>
                  //         Invalid Assessment
                  //       </Typography>
                  //     </div>
                  //   );
                  // }
                  // if (AssessmentActionStatusLog[2] === "C") {
                  //   return (
                  //     <History
                  //       ParameterAssessment={ParameterAssessment}
                  //       RatingScaleID={MapData.RatingScaleID}
                  //       TargetRule={extractTargetRules(
                  //         ParameterMapDetails.TargetRule
                  //       )}
                  //     />
                  //   );
                  // }

                  return (
                    <Assessment
                      collapse={collapse}
                      handleTabEdit={handleTabEdit}
                      onChangeAssessment={(StepOwnerLabel, state) => {
                        onChangeAssessment(
                          parameter.ParameterID,
                          doc.Header.DocumentID,
                          StepOwnerLabel,
                          state
                        );
                      }}
                      actorAssessment={actorAssessment}
                      TransactionID={
                        parameter.ParameterID || parameter.TransactionID
                      }
                      ParameterRatingScaleID={MapData.RatingScaleID}
                      ParameterTargetDataType={TargetDataType}
                      ParameterTargetRule={extractTargetRules(
                        TargetsEnabled + TargetType + TargetsMapping
                      )}
                      ParameterAchievementDataType={AchievementDataType}
                      AssessmentPeriodType={doc.Data.ReviewPeriodType}
                      AssessmentPeriodValue={doc.Data.ReviewPeriodUnit}
                      AssessmentStartDate={dateGenerator(
                        doc.Data.ReviewFromDate
                      )}
                      AssessmentEndDate={dateGenerator(doc.Data.ReviewTillDate)}
                      ParameterTargets={parameter.Targets}
                      ParameterWeightage={
                        ParameterWeightRule !== "D"
                          ? ParameterAssessment.Weight + "%" || "0%"
                          : null
                      }
                      ParameterActorAssessments={
                        ParameterAssessment.ActorAssessments
                      }
                      // ActionStatusLog={AssessmentActionStatusLog}
                      Workflow={doc.Workflow}
                      AssessmentDocumentID={doc.Header.DocumentID}
                      onSave={(
                        updatedAssessments,
                        AssessmentDocumentID,
                        workflowData
                      ) =>
                        saveParameterAssessments(
                          updatedAssessments,
                          parameter.ParameterID || parameter.ParameterId,
                          AssessmentDocumentID,
                          workflowData
                        )
                      }
                    />
                  );
                })}
              </PhaseAccordian>
            );
          } else if (AssessmentDocument.length === 1) {
            AssessmentDocument = AssessmentDocument[0];
            const ParameterAssessment = AssessmentDocument.Data.Parameters.find(
              (p) =>
                p.ParameterID ===
                (parameter.ParameterID || parameter.TransactionID)
            );
            console.log("Assesment Status: ", ParameterAssessment);
            // const AssessmentActionStatus = ActionStatusLogs.find(
            //   (log) =>
            //     log.TransactionDocumentID ===
            //     AssessmentDocument.Header.DocumentID
            // );

            // if (
            //   !AssessmentActionStatus ||
            //   !AssessmentActionStatus.ActionStatus
            // ) {
            //   return (
            //     <div className="Flex">
            //       <Typography variant="body1" fontWeight={500}>
            //         Invalid Assessment
            //       </Typography>
            //     </div>
            //   );
            // }
            // const AssessmentActionStatusLog =
            //   AssessmentActionStatus.ActionStatus;
            // [
            //   AssessmentActionStatus.ActionStatus.length - 1
            // ];

            // if (AssessmentActionStatusLog.length !== 3) {
            //   return (
            //     <div className="Flex">
            //       <Typography variant="body1" fontWeight={500}>
            //         Invalid Assessment
            //       </Typography>
            //     </div>
            //   );
            // }
            // if (AssessmentActionStatusLog[2] === "C") {
            //   return (
            //     <History
            //       ParameterAssessment={ParameterAssessment}
            //       RatingScaleID={MapData.RatingScaleID}
            //       TargetRule={extractTargetRules(
            //         ParameterMapDetails.TargetRule
            //       )}
            //     />
            //   );
            // }
            // {
            //   console.log("Achivements befor eass: ", Achievements);
            // }
            return (
              <Assessment
                collapse={collapse}
                onChangeAssessment={(StepOwnerLabel, state) => {
                  onChangeAssessment(
                    parameter.ParameterID,
                    AssessmentDocument.Header.DocumentID,
                    StepOwnerLabel,
                    state
                  );
                }}
                handleTabEdit={handleTabEdit}
                Achievements={Achievements}
                actorAssessment={actorAssessment}
                TransactionID={parameter.ParameterID || parameter.TransactionID}
                ParameterRatingScaleID={MapData.RatingScaleID}
                ParameterTargetDataType={TargetDataType}
                ParameterTargetRule={extractTargetRules(
                  TargetsEnabled + TargetType + TargetsMapping
                )}
                ParameterAchievementDataType={AchievementDataType}
                AssessmentPeriodType={AssessmentDocument.Data.ReviewPeriodType}
                AssessmentPeriodValue={AssessmentDocument.Data.ReviewPeriodUnit}
                AssessmentStartDate={dateGenerator(
                  AssessmentDocument.Data.ReviewFromDate
                )}
                AssessmentEndDate={dateGenerator(
                  AssessmentDocument.Data.ReviewTillDate
                )}
                ParameterTargets={parameter.Targets}
                ParameterWeightage={
                  ParameterWeightRule !== "D"
                    ? ParameterAssessment.Weight + "%" || "0%"
                    : null
                }
                ParameterActorAssessments={ParameterAssessment.ActorAssessments}
                // ActionStatusLog={AssessmentActionStatusLog}
                Workflow={AssessmentDocument.Workflow}
                AssessmentDocumentID={AssessmentDocument.Header.DocumentID}
                onSave={(
                  updatedAssessments,
                  AssessmentDocumentID,
                  workflowData
                ) =>
                  saveParameterAssessments(
                    updatedAssessments,
                    parameter.ParameterID || parameter.ParameterId,
                    AssessmentDocumentID,
                    workflowData
                  )
                }
              />
            );
          } else {
            return (
              <PhaseAccordian
                TransactionID={parameter.ParameterID || parameter.TransactionID}
                expandable={false}
                locked={true}
                title={
                  PeriodMap[period.TargetPeriodType].values[
                    period.TargetPeriodValue
                  ]
                }
                caption={`${dateGenerator(
                  period.TargetFromDate
                )} - ${dateGenerator(period.TargetTillDate)}`}
              />
            );
          }
        })}
    </>
  );
}

const initDimensionData = {
  TransactionID: "",
  DimensionID: "",
  DimensionName: "",
  DimensionDescription: "",
  SecondGroups: [],
  FirstGroups: [],
  Parameters: [],
  MapStructure: "",
  AddRule: "",
  DiscardRule: "",
  WeightRules: "",
  AppraiseeUserID: "",
  EventType: "",
  PeriodStartTimestamp: "",
  PeriodEndTimestamp: "",
  WorkflowID: "",
};

function getFilteredParameters(
  secondGroups,
  firstGroups,
  parameterArray,
  parameters
) {
  console.log(
    secondGroups,
    firstGroups,
    parameterArray,
    parameters,
    "THESE ARE UPDATED DATA"
  );

  // if (parameterArray.length !== parameters.length) return [];

  const len = parameters.length;
  let filteredParameters = [];
  let obj = {};
  for (let i = 0; i < len; i++) {
    obj[parameters[i].ParameterID] = parameters[i];
  }
  for (let i = 0; i < parameterArray.length; i++) {
    if (obj[parameterArray[i].ParameterID]) {
      obj[parameterArray[i].ParameterID].ParameterName =
        parameterArray[i].ParameterName;
      obj[parameterArray[i].ParameterID].ParameterDescription =
        parameterArray[i].ParameterDescription;
    }
  }

  filteredParameters = Object.entries(obj).map(([key, value]) => value);
  // for (let i = 0; i < len; i++) {
  //   filteredParameters.push({ ...parameterArray[i], ...parameters[i] });
  // }

  filteredParameters = filteredParameters.map((p) => ({
    ...p,
    ParameterGroupID: p.FirstGroupID || p.FirstGroupId,
  }));

  let filteredFirstGroups = [];

  filteredParameters.forEach((p) => {
    const index = firstGroups.findIndex(
      (g) => g.GroupID === (p.FirstGroupID || p.FirstGroupId)
    );

    if (index !== -1) {
      const filteredFirstGroup = { ...(firstGroups[index] || {}) };
      filteredFirstGroup.SecondGroupID = p.SecondGroupID || p.SecondGroupId;
      filteredFirstGroup.TransactionID = filteredFirstGroup.GroupID;
      filteredFirstGroups.push(filteredFirstGroup);
    }
  });
  let filterSet = new Set();
  filteredFirstGroups = filteredFirstGroups.filter(({ TransactionID }) => {
    if (!filterSet.has(TransactionID)) {
      filterSet.add(TransactionID);
      return true;
    }
    return false;
  });
  filterSet = new Set();
  const filteredSecondGroups = secondGroups
    .map((g) => {
      if (!filterSet.has(g.GroupID)) {
        filterSet.add(g.GroupID);
        return {
          ...g,
          TransactionID: g.GroupID,
        };
      }
      return null;
    })
    .filter((item) => item);

  return { filteredFirstGroups, filteredParameters, filteredSecondGroups };
}

function createDimensionData(data) {
  console.log(data.FirstGroupArray, "THESE ARE UPDATED DATA");
  if (!data) return { ...initDimensionData };

  const filteredData = getFilteredParameters(
    data.SecondGroupArray || [],
    data.FirstGroupArray || [],
    data.ParameterArray || [],
    data.Parameters || []
  );
  console.log("Filterd data: ", filteredData.filteredFirstGroups);

  const DimensionID = data.DimensionID;
  const TargetEditing = (data.MasterPlanData.Dimension || []).find(
    (d) => d.DimensionID === DimensionID
  )?.TargetEditing;

  return {
    DimensionID,
    DimensionName: data.DimensionName,
    DimensionDescription: data.DimensionDescription,
    SecondGroups: filteredData.filteredSecondGroups,
    FirstGroups: filteredData.filteredFirstGroups,
    Parameters: filteredData.filteredParameters,
    TargetEditing,
    MapStructure: data.ParameterMapData.MapStructure,
    AddRule: data.ParameterMapData.AddRule,
    DiscardRule: data.ParameterMapData.DiscardRule,
    WeightRules:
      data.ParameterMapData.SecondGroupWeightRule +
      data.ParameterMapData.FirstGroupWeightRule +
      data.ParameterMapData.ParameterWeightRule,
  };
}

// function ParameterAssessmentsReducer(ParameterAssessments) {
//   if (!ParameterAssessments) return {};
//   const Assessments = [...JSON.parse(JSON.stringify(ParameterAssessments))];

//   return Assessments.reduce((result, assess) => {
//     const DocumentID = assess.Header.DocumentID;
//     const Parameters = [...assess.Data.Parameters];

//     const parameterState = Parameters.reduce((total, current) => {
//       if (!total[current.ParameterID]) {
//         total[current.ParameterID] = {};
//       }
//       total[current.ParameterID][current.ReviewPeriodUnit] =
//         current.ActorAssessments;
//       return total;
//     }, {});

//     result[DocumentID] = parameterState;
//     return result;
//   }, {});
// }

const DimensionPeriod = ({ DimensionPeriodData, handleTabEdit }) => {
  console.log(
    JSON.stringify(DimensionPeriodData),
    DimensionPeriodData,
    "PMS DATA"
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let filteredAssesment = DimensionPeriodData.ParameterAssessments.filter(
      (item) => item
    );
    const EPP = DimensionPeriodData.EmployeePerformancePlanData;
    const Dimension = EPP.Dimensions.find(
      (d) => d.DimensionID === DimensionPeriodData.DimensionID
    );

    const DimensionPhase = filteredAssesment.length > 0 ? "A" : "G";
    console.log(DimensionPhase, "DIMENSIONPHASE");
    let Users = null;
    let AggregateScores = null;
    if (DimensionPhase === "G") {
      Users = DimensionPeriodData.Workflow?.WorkflowSteps?.filter(
        (step) => step.StepID !== "000"
      ).map((step) => ({
        label: step.StepOwnerLabel,
        value: step.StepOwnerUserIDs[0],
        order:
          step.StepOwnerLabel === "Appraisee"
            ? 0
            : step.StepOwnerLabel === "Appraiser"
            ? 1
            : 2,
      }));
    } else {
      let LatestAssessment = DimensionPeriodData.ParameterAssessments?.find(
        (assessment) => {
          let temp = assessment || {};
          return temp.Workflow && temp.Workflow.WorkflowStatus !== "C";
        }
      );
      if (!LatestAssessment) {
        console.log(
          (
            DimensionPeriodData.ParameterAssessments[
              DimensionPeriodData.ParameterAssessments?.length - 1
            ] || {}
          ).Workflow?.WorkflowSteps || [],
          "USSSER _ARRAY"
        );
        Users = (
          (
            DimensionPeriodData.ParameterAssessments[
              DimensionPeriodData.ParameterAssessments?.length - 1
            ] || {}
          ).Workflow?.WorkflowSteps || []
        )
          .filter((step) => step.StepID !== "000")
          .map((step) => ({
            label: step.StepOwnerLabel,
            value: step.StepOwnerUserIDs[0],
            order:
              step.StepOwnerLabel === "Appraisee"
                ? 0
                : step.StepOwnerLabel === "Appraiser"
                ? 1
                : 2,
          }));
        AggregateScores = (
          DimensionPeriodData.ParameterAssessments[
            DimensionPeriodData.ParameterAssessments?.length - 1
          ].Data.PeriodAggregateScore || []
        )
          .filter((score) => score.ActorType !== "Appraisee")
          .filter((score) => score.AggregateScore);
      } else {
        console.log(LatestAssessment.Workflow?.WorkflowSteps, "USSSER _ARRAY");
        Users = LatestAssessment.Workflow?.WorkflowSteps?.filter(
          (step) => step.StepID !== "000"
        ).map((step) => ({
          label: step.StepOwnerLabel,
          value: step.StepOwnerUserIDs[0],
          order:
            step.StepOwnerLabel === "Appraisee"
              ? 0
              : step.StepOwnerLabel === "Appraiser"
              ? 1
              : 2,
        }));
        AggregateScores = (LatestAssessment.Data.PeriodAggregateScore || [])
          .filter((score) => score.ActorType !== "Appraisee")
          .filter((score) => score.AggregateScore);
      }
    }
    console.log(Users, "USSSER _ARRAY");
    if (!Users) {
      // window.location.reload();
      return;
    }
    dispatch({
      type: Actions.ADD_SIDE_COMPONENT,
      payload: {
        Component: SidePanel,
        props: {
          ViewName: DimensionPeriodData.DimensionName,
          PlanStartDate:
            DimensionPeriodData.EmployeePerformancePlanData.PlanStartDate,
          PlanEndDate:
            DimensionPeriodData.EmployeePerformancePlanData.PlanEndDate,
          PlanStage: DimensionPhase,
          WorkflowUsers: Users?.sort((a, b) => {
            return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
          }),
          AggregateScores:
            getUserId() ===
            DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID
              ? undefined
              : AggregateScores,
          ScoreDistributionProps: {
            DimensionID: DimensionPeriodData.DimensionID,
            UserID: getUserId(),
            Visibility: Dimension.AppraiserID === getUserId(),
          },
        },
      },
    });
  }, []);
  // This state is for handling Modals.
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState({});

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState("Loading...");
  const [missingParameters, setMissingParameters] = useState([]);
  const [appraiserLoading, setAppraiserLoading] = useState(false);
  const [confidentialPayload, setConfidentialPayload] = useState(null);

  useEffect(() => {
    getData({
      featureId: FeatureIDs.ConfidentialAssessment,
      payload: {
        "Data.AppraiseeUserID":
          DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID,
        "Data.EmployeePlanID":
          DimensionPeriodData.EmployeePerformancePlanData.TransactionID,
      },
    }).then((res2) => {
      console.log(res2, "THIS IS CONFIDENTIAL ASSESSMENT");
      if (res2 && res2?.Data) setConfidentialPayload(res2.Data[0]);
    });
  }, []);

  async function getDimensionAssessmentDocs(DimensionID) {
    return await getData({
      featureId: FeatureIDs.ParameterAsseessment,
      payload: {
        "Data.AppraiseeUserID":
          DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID,
        "Data.DimensionID": DimensionID,
        "Data.EmployeePerformancePlanID":
          DimensionPeriodData.EmployeePerformancePlanData.TransactionID,
      },
      fields: ["Header.*", "Data.*", "Workflow.*"],
    });
  }
  async function editEPP() {
    const edit = {
      TransactionID:
        DimensionPeriodData.EmployeePerformancePlanData.TransactionID,
      EffectiveFromTimestamp:
        DimensionPeriodData.EmployeePerformancePlanData.EffectiveFromTimestamp,
      RequestType: "1",
    };

    await submitPayload({
      featureId: FeatureIDs.EmployeePerformancePlan,
      edit,
      payload: {
        ...DimensionPeriodData.EmployeePerformancePlanData,
      },
    });
  }
  async function editConfidentialAssessment() {
    if (!confidentialPayload) return;
    console.log(confidentialPayload, "confidentialPayload");
    const FINAL_PAYLOAD = {
      EmployeePlanID: confidentialPayload.EmployeePlanID,
      PerformancePlanID: confidentialPayload.PerformancePlanID,
      AppraiseeUserID: confidentialPayload.AppraiseeUserID,
      AppraiserUserID: getUserId(),
      PerformanceSummary: confidentialPayload.PerformanceSummary,
      PotentialSummary: confidentialPayload.PotentialSummary,
      GrowthReadiness: confidentialPayload.GrowthReadiness,
      GrowthRoleID: confidentialPayload.GrowthRoleID,
      PromotionRecommendation: confidentialPayload.PromotionRecommendation,
      ExitRisk: confidentialPayload.ExitRisk,
    };

    const edit = {
      TransactionID: confidentialPayload.TransactionID,
      EffectiveFromTimestamp: confidentialPayload.EffectiveFromTimestamp,
      RequestType: "1",
    };

    await submitPayload({
      featureId: FeatureIDs.ConfidentialAssessment,
      payload: FINAL_PAYLOAD,
      edit,
    });
  }
  async function editDimension(ParameterAssessment, ActionType) {
    const AssessmentDoc = {
      ParametersAndTargetDocumentID: "",
      Parameters: "",
      DimensionParameterAssessmentID: "",
      EventType: "",
      ReviewFromDate: "",
      PeriodAggregateScore: "",
      EmployeePerformancePlanID: "",
      WorkflowID: "",
      AppraiseeUserID: "",
      MasterPlanID: "",
      ReviewPeriodType: "",
      InterimReason: "",
      ReviewTillDate: "",
      DimensionID: "",
      ReviewPeriodUnit: "",
    };

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    const Header = {
        TransactionID: ParameterAssessment.TransactionID,
        EffectiveFromTimestamp: ParameterAssessment.EffectiveFromTimestamp,
      },
      Data = Object.keys(AssessmentDoc).reduce((total, current) => {
        total[current] = ParameterAssessment[current];
        return total;
      }, {});

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = ParameterAssessment[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);
    let stepId = workflowProcesser.getCurrentStepID();
    const WorkflowID = Workflow.WorkflowID;
    let obj = {
      stepId,
      action: ActionType,
      WorkflowID,
    };

    let edit = {
      TransactionID: Header.TransactionID,
      EffectiveFromTimestamp: Header.EffectiveFromTimestamp,
    };
    console.log("Before sending: pms", Data, " ", obj, " ", edit, Workflow);

    return await submitPayload(
      ActionType
        ? {
            featureId: "19152",
            payload: Data,
            updateAction: obj,
            edit,
          }
        : {
            featureId: "19152",
            payload: Data,
            edit,
          }
    );
  }
  async function saveEPPConfidentialGoalsCoreValues() {
    try {
      setAppraiserLoading(true);
      await editEPP();
      await editConfidentialAssessment();
      const appraiserAssessments = [];
      const OtherDimensionID =
        DimensionPeriodData.EmployeePerformancePlanData.Dimensions.find(
          (d) => d.DimensionID !== dimensionData.DimensionID
        )?.DimensionID;
      const res = await getDimensionAssessmentDocs(OtherDimensionID);

      const totalDocuments = [
        ...ParameterAssessments.map((p) => ({
          ...p.Data,
          ...p.Header,
          ...p.Workflow,
        })),
        ...(typeof res === "string" ? [] : res.Data),
      ];
      totalDocuments.forEach((a) => {
        const WorkflowDoc = {
          ConversationID: "",
          WorkflowID: "",
          WorkflowStatus: "",
          WorkflowSteps: "",
        };

        const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
          total[current] = a[current];
          return total;
        }, {});

        if (Workflow && Workflow.WorkflowSteps) {
          const processor = new WorkflowProcesser(Workflow);
          if (
            processor.getCurrentStepData()?.StepOwnerLabel === "Appraiser" &&
            processor.getCurrentStepUserID() === getUserId()
          ) {
            console.log(
              processor.getCurrentStepData(),
              "processor.getCurrentStepData()"
            );
            appraiserAssessments.push({
              data: a,
              actionType: processor.getCurrentStepData()?.AllowedActions[0],
            });
          }
        }
      });
      for (let i = 0; i < appraiserAssessments.length; i++) {
        const document = appraiserAssessments[i];
        console.log(document, "THISISGETTINGSAVEDASSESSMENT NOW");
        await editDimension(document.data, undefined);
      }
      setAppraiserLoading(false);
      setActionError({
        type: 0,
        message: "Saved the complete plan successfully",
      });
    } catch (e) {
      setActionError({
        type: 0,
        message: "Error occured: " + e,
      });
    }
  }

  function isAppraiser() {
    const index = ParameterAssessments.findIndex(
      (p) => p.Workflow.WorkflowStatus !== "C"
    );
    if (index !== -1) {
      const assess = ParameterAssessments[index];
      const workflow = new WorkflowProcesser(assess.Workflow);
      return (
        workflow?.getCurrentStepData().StepOwnerLabel === "Appraiser" &&
        workflow?.getCurrentStepData().StepOwnerUserIDs[0] === getUserId()
      );
    } else {
      return false;
    }
  }

  // const [actorAssessments, setActorAssessments] = useState(
  //   // ParameterAssessmentsReducer(DimensionPeriodData.ParameterAssessments)
  //   {}
  // );

  //  console.log(JSON.stringify(actorAssessments), "ACTOR ASSESSMENTS");
  const [actionError, setActionError] = useState(null);
  // This state is for loading the jsx while data is being fetched.

  // This State stores the complete data related to this dimension
  const [dimensionData, setDimensionData] = useState(
    createDimensionData({ ...DimensionPeriodData })
  );
  const [payload, setPayload] = useState({
    ...DimensionPeriodData,
  });
  const [actorAssessment, setActorAssessment] = useState({});
  // const dimensionName = dimensionData.DimensionName;
  const dimensionDescription = dimensionData.DimensionDescription || "";

  const secondGroups = dimensionData.SecondGroups;
  const firstGroups = dimensionData.FirstGroups;
  const parameters = dimensionData.Parameters;
  console.log("Second group is: ", firstGroups);
  const groupStruct = extractDimensionStructure(dimensionData.MapStructure);
  const addStruct = extractAddRules(dimensionData.AddRule);
  const deleteStruct = extractDiscardRules(dimensionData.DiscardRule);
  const weightStruct = extractWeightRules(dimensionData.WeightRules);

  function generateTargetDataTypesFromPayload() {
    const dataTypes = [];
    if (!payload.ParameterMapData) return dataTypes;
    if (payload.ParameterMapData.UserTargetST === "E") {
      dataTypes.push("ST");
    }
    if (payload.ParameterMapData.UserTargetLT === "E") {
      dataTypes.push("LT");
    }
    if (payload.ParameterMapData.UserTargetNI === "E") {
      dataTypes.push("NI");
    }
    if (payload.ParameterMapData.UserTargetND === "E") {
      dataTypes.push("ND");
    }
    if (payload.ParameterMapData.UserTargetPI === "E") {
      dataTypes.push("PI");
    }
    if (payload.ParameterMapData.UserTargetPD === "E") {
      dataTypes.push("PD");
    }
    return dataTypes;
  }

  const UserDefinedParameterTargetRules = {
    TargetsEnabled: payload.ParameterMapData?.UserTargetOff === "E" ? "D" : "E",
    TargetType: "FV",
    TargetMapping: "U",
    TargetDataTypes: generateTargetDataTypesFromPayload(),
  };

  const secondGroup = {
    list: secondGroups,
    disabled: isDisabled(groupStruct.group2),
    props: {
      clickable: false,
      onClickHandler: () => {},
      permissions: {
        addable: !(isRestricted(addStruct.group2) || isNA(addStruct.group2)),
        editable: isUserDefined(addStruct.group2),
        deletable: isPermitted(deleteStruct.group2),
      },
      rules: {
        addRule: addStruct.group2,
        deleteRule: deleteStruct.group2,
        weightRule: weightStruct.group2,
      },
      addModalHandler: () => {
        setModal({
          Component: AddGroup,
          props: {
            type: "g2",
            rule: addStruct.group2,
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        const record = secondGroups.find(
          (group) => group.TransactionID === TransactionID
        );

        setModal({
          Component: AddGroup,
          props: {
            type: "g2",
            rule: addStruct.group2,
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, TransactionID) => {
        const record = secondGroups.find(
          (group) => group.TransactionID === TransactionID
        );

        setModal({
          Component: DeleteAlert,
          props: {
            name: record.GroupName,
            closeAlert: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  const firstGroup = {
    list: firstGroups,
    disabled: isDisabled(groupStruct.group1),
    props: {
      clickable: false,
      onClickHandler: () => {},
      permissions: {
        addable: !(isRestricted(addStruct.group1) || isNA(addStruct.group1)),
        editable: isUserDefined(addStruct.group1),
        deletable: isPermitted(deleteStruct.group1),
      },
      rules: {
        addRule: addStruct.group1,
        deleteRule: deleteStruct.group1,
        weightRule: weightStruct.group1,
      },
      addModalHandler: (SecondGroupID) => {
        setModal({
          Component: AddGroup,
          props: {
            type: "g1",
            rule: addStruct.group1,
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        const record = firstGroups.find(
          (group) => group.TransactionID === TransactionID
        );
        setModal({
          Component: AddGroup,
          props: {
            type: "g1",
            rule: addStruct.group1,
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, TransactionID) => {
        const record = firstGroups.find(
          (group) => group.TransactionID === TransactionID
        );

        setModal({
          Component: DeleteAlert,
          props: {
            name: record.GroupName,
            closeAlert: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  function saveTargetsByParameterID(updatedTargets, ParameterID) {
    const Parameters = [...dimensionData.Parameters];
    const index = Parameters.findIndex((p) => p.ParameterID === ParameterID);
    Parameters[index].Targets = updatedTargets;
    setDimensionData((prevState) => {
      console.log(Parameters, "PAYLOAD IS THIS");
      return { ...prevState, Parameters };
    });
    const GOAL_SETTINGS_PAYLOAD = { ...payload, Parameters };
    console.log(
      updatedTargets,
      ParameterID,
      GOAL_SETTINGS_PAYLOAD,
      "GOAL_SETTINGS_PAYLOAD"
    );
    setPayload(GOAL_SETTINGS_PAYLOAD);
  }

  function saveGoalSettingTargets(stepId, action) {
    if (action === "Submit") setMessage("Submitting Targets...");
    else if (action === "Approve") setMessage("Approving Targets...");
    setLoading(true);
    const GOAL_SETTINGS_PAYLOAD = { ...payload };

    console.log(GOAL_SETTINGS_PAYLOAD, "GOAL_SETTINGS_PAYLOAD");
    GOAL_SETTINGS_PAYLOAD.Parameters.forEach((p) => {
      p.Targets.forEach((t) => {
        delete t["TargetRange"];
        if (!t.MultipleTargets) t.MultipleTargets = [];
        // if (!t.SingleTarget) t.SingleTarget = "";
      });
    });

    GOAL_SETTINGS_PAYLOAD.DimensionParameterID = {
      ...GOAL_SETTINGS_PAYLOAD,
    }.TransactionID;

    const edit = {
      TransactionID: GOAL_SETTINGS_PAYLOAD.TransactionID,
      EffectiveFromTimestamp: GOAL_SETTINGS_PAYLOAD.EffectiveFromTimestamp,
      RequestType: "1",
    };

    console.log(
      JSON.stringify(GOAL_SETTINGS_PAYLOAD),
      "THIS IS UPDATED PAYLOAD"
    );
    let workFlowId = GOAL_SETTINGS_PAYLOAD.WorkflowID;
    setDimensionData(createDimensionData({ ...GOAL_SETTINGS_PAYLOAD }));

    delete GOAL_SETTINGS_PAYLOAD["ParameterMapData"];
    delete GOAL_SETTINGS_PAYLOAD["EmployeePerformancePlanData"];
    delete GOAL_SETTINGS_PAYLOAD["EffectiveFromTimestamp"];
    delete GOAL_SETTINGS_PAYLOAD["EffectiveTillTimestamp"];
    delete GOAL_SETTINGS_PAYLOAD["SecondGroupArray"];
    delete GOAL_SETTINGS_PAYLOAD["FirstGroupArray"];
    delete GOAL_SETTINGS_PAYLOAD["ParameterArray"];
    delete GOAL_SETTINGS_PAYLOAD["TransactionID"];
    delete GOAL_SETTINGS_PAYLOAD["Workflow"];
    delete GOAL_SETTINGS_PAYLOAD["ParameterAssessments"];
    delete GOAL_SETTINGS_PAYLOAD["DimensionPhase"];
    delete GOAL_SETTINGS_PAYLOAD["MasterPlanData"];
    delete GOAL_SETTINGS_PAYLOAD["WorkflowDocument"];
    delete GOAL_SETTINGS_PAYLOAD["ParameterAndTargetDocumentID"];
    delete GOAL_SETTINGS_PAYLOAD["EventType"];
    //EventType

    let updateAction = undefined;

    if (action) {
      updateAction = {
        stepId: stepId,
        action: action,
        WorkflowID: workFlowId,
      };
    }
    console.log("Before Library: ", updateAction, " ", action);
    submitPayload({
      featureId: "19153",
      edit,
      payload: GOAL_SETTINGS_PAYLOAD,
      updateAction,
    })
      .then((response) => {
        console.log(response, "THIS IS RESPONSE");
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });

    console.log(JSON.stringify(GOAL_SETTINGS_PAYLOAD), "GOAL_SETTINGS_PAYLOAD");
  }

  function saveParameterAssessments(
    updatedAssessments,
    ParameterID,
    AssessmentDocumentID,
    workflowData
  ) {
    setLoading(true);
    console.log(payload, "THIS IS THE PARAMETER INDEX ", updatedAssessments);
    let obj = actorAssessment;

    let updateOtherStates = workflowData.updateOtherStates;
    if (workflowData.updateOtherStates) {
      delete workflowData["updateOtherStates"];
    }
    console.log("Before if: ", obj);
    if (obj[AssessmentDocumentID]) {
      obj[AssessmentDocumentID][ParameterID] = updatedAssessments;
      obj[AssessmentDocumentID].workflowData = workflowData;
    } else {
      obj[AssessmentDocumentID] = {
        [ParameterID]: updatedAssessments,
        workflowData,
      };
    }
    console.log("After IF", obj);
    const ParameterAssessments = [
      ...JSON.parse(JSON.stringify(payload.ParameterAssessments)),
    ];

    console.log(ParameterAssessments, "PARAMETER ASSESSMENTS ARE HERE");

    const AssessmentDocument = [...ParameterAssessments].find(
      (assess) => assess.Header.DocumentID === AssessmentDocumentID
    );

    let Data = { ...AssessmentDocument.Data };
    console.log("before data: ", Data);
    delete Data["prevAssesmentObject"];
    console.log("Afetr data: ", Data);
    const Parameters = [...Data.Parameters];

    const index = [...Parameters].findIndex(
      (p) => p.ParameterID === ParameterID
    );
    if (index === -1) return setLoading(false);

    const ActorAssessments = [...updatedAssessments];
    Parameters[index].ActorAssessments = [...Object.values(ActorAssessments)];

    const ASSESSMENT_PAYLOAD = { ...AssessmentDocument.Data, Parameters };

    let edit = {
      TransactionID: AssessmentDocument.Header.TransactionID,
      EffectiveFromTimestamp: AssessmentDocument.Header.EffectiveFromTimestamp,
    };

    console.log(ASSESSMENT_PAYLOAD, edit, "ASSESSMENT PAYLOAD");

    submitPayload({
      featureId: "19152",
      edit,
      payload: JSON.parse(JSON.stringify(ASSESSMENT_PAYLOAD)),
    })
      .then((response) => {
        console.log(response, "THIS IS RESPONSE after update");
        setPayload((prevState) => {
          const newState = JSON.parse(JSON.stringify({ ...prevState }));
          console.log(newState, "newState");
          let index = newState.ParameterAssessments.findIndex(
            (p) => p.Header.DocumentID === AssessmentDocumentID
          );
          if (index === -1) return prevState;

          let index2 = newState.ParameterAssessments[
            index
          ].Data.Parameters.findIndex((p) => p.ParameterID === ParameterID);

          if (index2 === -1) return prevState;
          newState.ParameterAssessments[index].Data.Parameters[
            index2
          ].ActorAssessments = updatedAssessments;
          return newState;
        });
        setActorAssessment({ ...obj });
      })
      .catch({})
      .finally(() => {
        setLoading(false);
        if (updateOtherStates) {
          updateOtherStates(false, "isLoading");
        }
      });
    return;
    // const currentAssessmentDocument = payload.ParameterAssessments.find(
    //   (doc) => doc.Header.DocumentID === AssessmentDocumentID
    // );
    // let AssesmentData = currentAssessmentDocument.Data;
    // let Parameters = [...AssesmentData.Parameters];
    // const index = Parameters.findIndex((p) => p.ParameterID === ParameterID);

    // Parameters[index].ActorAssessments = updatedAssessments;
    // console.log(Parameters, "THIS IS THE PARAMETER INDEX");

    // // Parameters = Parameters.map((p) => ({
    // //   SecondGroupID: p.SecondGroupID,
    // //   FirstGroupID: p.FirstGroupID,
    // //   SecondGroupName: p.SecondGroupName,
    // //   FirstGroupName: p.FirstGroupName,
    // //   ParameterID: p.ParameterID,
    // //   ParameterName: p.ParameterName,
    // //   UnitOfMeasurement: p.UnitOfMeasurement,
    // //   Weight: p.Weight,
    // //   Target: p.Target,
    // //   ActorAssessments: p.ActorAssessments,
    // // }));

    // //const ParameterAssessmentsData = { ...currentAssessmentDocument };

    // const PARAMETER_ASSESSMENTS_PAYLOAD = {
    //   ...AssesmentData,
    //   Parameters,
    // };

    // console.log(
    //   Parameters,
    //   PARAMETER_ASSESSMENTS_PAYLOAD,
    //   "THESE ARE THE PARAMETERS"
    // );
    // const edit = {
    //   TransactionID: currentAssessmentDocument.Header.TransactionID,
    //   EffectiveFromTimestamp:
    //     currentAssessmentDocument.Header.EffectiveFromTimestamp,
    //   RequestType: "1",
    // };

    // //console.log(Parameters, "THIS IS THE PARAMETER INDEX");

    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["MasterPlanData"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["EffectiveFromTimestamp"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["EffectiveTillTimestamp"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["TransactionID"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["EmployeePerformancePlanData"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["FirstGroupArray"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["SecondGroupArray"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["ParameterArray"];
    // // delete PARAMETER_ASSESSMENTS_PAYLOAD["Workflow"];
    // console.log("Before assement submit: ", workflowData);

    // submitPayload({
    //   featureId: "19152",
    //   edit,
    //   payload: PARAMETER_ASSESSMENTS_PAYLOAD,
    //   updateAction: workflowData,
    // })
    //   .then((response) => {
    //     console.log(response, "THIS IS RESPONSE after update");
    //   })
    //   .catch({})
    //   .finally(() => {
    //     if (updateOtherStates) {
    //       updateOtherStates(false, "isLoading");
    //     }
    //   });

    // console.log(
    //   JSON.stringify(PARAMETER_ASSESSMENTS_PAYLOAD),
    //   "THIS IS PARAMETER ASSESSMENTS PAYLOAD"
    // );
  }

  function onChangeAssessment(ParameterID, DocID, StepOwnerLabel, state) {
    console.log(
      ParameterID,
      DocID,
      StepOwnerLabel,
      state,
      "THISISTHE REQUIRED PARAMS HERE"
    );
    let { ParameterAssessments } = payload;
    console.log("payload is: ", ParameterAssessments, " ", actorAssessment);
    let index = ParameterAssessments.findIndex(
      (item) => item?.Header.DocumentID === DocID
    );
    if (index === -1) return;

    //let assesmentsId = ParameterAssessments[index].Header.DocumentID;
    // let paramterMap = {};
    let ownerIndex =
      StepOwnerLabel === "Appraiser"
        ? 1
        : StepOwnerLabel === "Appraisee"
        ? 0
        : 2;
    let Parameters = ParameterAssessments[index].Data.Parameters;
    const index2 = Parameters.findIndex((p) => p.ParameterID === ParameterID);
    if (index2 !== -1) {
      // Parameters[index2]
      if (Parameters[index2].ActorAssessments[ownerIndex]) {
        Parameters[index2].ActorAssessments[ownerIndex] = {
          ...Parameters[index2].ActorAssessments[ownerIndex],
          ...state,
        };
      }
      console.log(
        Parameters[index2].ActorAssessments[ownerIndex],
        state,
        "THISISTHE REQUIRED PARAMS HERE"
      );
    }
    ParameterAssessments[index].Data.Parameters = Parameters;
    payload.ParameterAssessments = [...ParameterAssessments];
    console.log(payload, "FINAL_UPDATED_PAYLOAD");
    setPayload(payload);
  }

  const parameter = {
    list: parameters.filter((p) => p.ParameterID !== ""),
    disabled: isDisabled(groupStruct.parameter),
    expandable: true,
    children: {
      Component: ParameterChildComponent,
      props: {
        dimensionId: dimensionData.DimensionID,
        dimensionPhase: DimensionPeriodData.DimensionPhase,
        targetEditing: dimensionData.TargetEditing || "D",
        payloadData: payload,
        saveGoalSettingTargets: saveTargetsByParameterID,
        saveParameterAssessments,
        actorAssessment,
        handleTabEdit,
        onChangeAssessment,
      },
    },
    props: {
      clickable: false,
      onClickHandler: () => {},
      permissions: {
        addable: !(
          isRestricted(addStruct.parameter) || isNA(addStruct.parameter)
        ),
        editable: isUserDefined(addStruct.parameter),
        deletable: isPermitted(deleteStruct.parameter),
      },
      rules: {
        addRule: addStruct.parameter,
        deleteRule: deleteStruct.parameter,
        weightRule: weightStruct.parameter,
      },
      missingParameters,
      addModalHandler: (FirstGroupID) => {
        setModal({
          Component: AddGroup,
          props: {
            type: "p",
            rule: addStruct.parameter,
            targetRules: { ...UserDefinedParameterTargetRules },
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        const record = parameters.find(
          (group) => group.TransactionID === TransactionID
        );
        setModal({
          Component: AddGroup,
          props: {
            type: "p",
            rule: addStruct.parameter,
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, TransactionID) => {
        const record = parameters.find(
          (parameter) =>
            (parameter.TransactionID || parameter.ParameterID) === TransactionID
        );

        setModal({
          Component: DeleteAlert,
          props: {
            onDelete: () => {
              const oldParameters = [...parameters];
              const index = oldParameters.findIndex(
                (p) =>
                  (p.TransactionID || p.ParameterID) ===
                  (record.TransactionID || record.ParameterID)
              );
              if (index === -1) return;

              oldParameters.splice(index, 1);
              setDimensionData((prevState) => ({
                ...prevState,
                Parameters: [...oldParameters],
              }));
              return;
            },
            name: record.ParameterName || "Unknown Parameter",
            closeAlert: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  const userId = getUserId();
  let { DimensionPhase, ParameterAssessments } = payload;
  ParameterAssessments = ParameterAssessments || [];
  DimensionPeriodData.ParameterAssessments =
    DimensionPeriodData.ParameterAssessments || [];
  ParameterAssessments = ParameterAssessments.filter((item) => item);
  DimensionPhase = ParameterAssessments.length > 0 ? "A" : "G";
  let Workflows = [DimensionPeriodData?.Workflow];
  console.log(Workflows, "PARAMETER PROPS");
  // let WorkflowAction = "Submit";
  // const workflowProcesser = new WorkflowProcesser(Workflow);
  // const WorkflowStep = workflowProcesser.getCurrentStep();
  // const CurrentStepID = workflowProcesser.getCurrentStepID();
  // const CurrentStepUserId = workflowProcesser.getCurrentStepUserID();
  // console.log("User id is: ", userId, " ", CurrentStepUserId);

  if (DimensionPhase === "A")
    Workflows =
      ParameterAssessments.filter(
        (assessment) => assessment.Workflow?.WorkflowStatus !== "C"
      ).map((doc) => ({
        Workflow: doc?.Workflow,
        ReviewPeriodType: doc.Data.ReviewPeriodType,
        ReviewPeriodUnit: doc.Data.ReviewPeriodUnit,
        DocID: doc.Header.DocumentID,
      })) || Workflows;

  const MultipleWorkflowActions = [];
  console.log(Workflows, "MultipleWorkflowActions");
  Workflows.forEach(
    ({ Workflow, ReviewPeriodType, ReviewPeriodUnit, DocID }) => {
      console.log("Workflow: ", Workflow);
      if (!Workflow) return <>Data Missing Workflow</>;

      console.log(JSON.stringify(Workflow), DimensionPhase, "WORKFLOW ACTION");

      let WorkflowAction = null;
      const workflowProcesser = new WorkflowProcesser(Workflow);
      const WorkflowStep = workflowProcesser.getCurrentStep(); //returns index

      const CurrentStepID = workflowProcesser.getCurrentStepID();
      const CurrentUserStep = workflowProcesser.getStepByIndex(WorkflowStep); //return step object
      const CurrentStepUserId = workflowProcesser.getCurrentStepUserID(); //return user id
      const isActionOwner = userId === CurrentStepUserId;
      const { StepOwnerType, StepOwner, StepID, StepOwnerLabel } =
        CurrentUserStep || {};

      console.log(CurrentUserStep, getUserId(), "StepOwnerStepOwnerLabel");

      if (!DimensionPhase) return <>Missing Data Phase</>;
      console.log("User id is: workflow step", WorkflowStep, " ", Workflow);
      if (WorkflowStep === -1) {
        WorkflowAction = "";
      } else {
        if (DimensionPhase === "G") {
          if (userId === CurrentStepUserId) {
            WorkflowAction = WorkflowStep === 1 ? "Submit" : "Approve";
          } else {
            WorkflowAction =
              WorkflowStep === 1
                ? "Waiting for User to set the targets"
                : "Targets under review";
          }
        } else {
          if (isActionOwner) {
            if (StepOwnerType === "SU") {
              WorkflowAction = "Approve";
            } else if (StepOwnerType === "LM" && StepOwner === "1") {
              WorkflowAction = "Submit";
            } else {
              WorkflowAction = "Submit";
            }
          } else {
            if (StepOwnerType !== "SU") {
              WorkflowAction = "Waiting For Assesment";
            }
          }
        }
      }

      const WorkflowActions = workflowProcesser.getCurrentStepData()
        ?.AllowedActions || [WorkflowAction];

      console.log(
        workflowProcesser.getCurrentStepData(),
        WorkflowActions,
        WorkflowStep,
        "WORKFLOW ACTION"
      );
      MultipleWorkflowActions.push({
        ReviewPeriodType,
        ReviewPeriodUnit,
        WorkflowActions,
        StepOwnerLabel,
        CurrentStepID,
        WorkflowAction,
        CurrentStepUserId,
        DocID,
      });
    }
  );
  console.log(MultipleWorkflowActions, "MultipleWorkflowActions");

  function submitDimensionAssessmentDoc(ParameterAssessment, actionType) {
    const AssessmentDoc = {
      ParametersAndTargetDocumentID: "",
      Parameters: "",
      DimensionParameterAssessmentID: "",
      EventType: "",
      ReviewFromDate: "",
      PeriodAggregateScore: "",
      EmployeePerformancePlanID: "",
      WorkflowID: "",
      AppraiseeUserID: "",
      MasterPlanID: "",
      ReviewPeriodType: "",
      InterimReason: "",
      ReviewTillDate: "",
      DimensionID: "",
      ReviewPeriodUnit: "",
    };

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };
    const Header = ParameterAssessment.Header
        ? ParameterAssessment.Header
        : {
            TransactionID: ParameterAssessment.TransactionID,
            EffectiveFromTimestamp: ParameterAssessment.EffectiveFromTimestamp,
          },
      Data = ParameterAssessment.Data
        ? ParameterAssessment.Data
        : Object.keys(AssessmentDoc).reduce((total, current) => {
            total[current] = ParameterAssessment[current];
            return total;
          }, {});

    const Workflow = ParameterAssessment.Workflow
      ? ParameterAssessment.Workflow
      : Object.keys(WorkflowDoc).reduce((total, current) => {
          total[current] = ParameterAssessment[current];
          return total;
        }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);
    let stepId = workflowProcesser.getCurrentStepID();
    const WorkflowID = Workflow.WorkflowID;
    let obj = {
      stepId,
      // action: workflowProcesser.getStepByIndex(
      //   workflowProcesser.getCurrentStep()
      // ).AllowedActions[0],
      action: actionType,
      WorkflowID,
    };
    let edit = {
      TransactionID: Header.TransactionID,
      EffectiveFromTimestamp: Header.EffectiveFromTimestamp,
    };
    console.log("Before sending: pms", Data, " ", obj, " ", edit);

    return submitPayload({
      featureId: "19152",
      payload: Data,
      updateAction: obj,
      edit,
    });
  }

  // useEffect(() => {
  //   const OtherDimensionID =
  //     DimensionPeriodData.EmployeePerformancePlanData.Dimensions.find(
  //       (d) => d.DimensionID !== dimensionData.DimensionID
  //     )?.DimensionID;
  //   getData({
  //     featureId: FeatureIDs.ParameterAsseessment,
  //     payload: {
  //       "Data.AppraiseeUserID":
  //         DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID,
  //       "Data.DimensionID": OtherDimensionID,
  //       "Data.EmployeePerformancePlanID":
  //         DimensionPeriodData.EmployeePerformancePlanID,
  //     },
  //     fields: ["Header.*", "Data.*", "Workflow.*"],
  //   }).then((res) => {
  //     if (typeof res !== "string" && res.Data.length > 0) {
  //       console.log(res.Data, "NONFINALPERIODASSESSMENTS");
  //     }
  //   });
  // }, []);

  function isValidAssessment(assessment) {
    const parameters = assessment?.Parameters;
    if (parameters) {
      for (let i = 0; i < parameters.length; i++) {
        const parameter = parameters[i];
        const index = parameter?.ActorAssessments?.findIndex(
          (a) => a.ActorType === "Appraiser"
        );
        if (index && index !== -1) {
          const data = parameter?.ActorAssessments[index];
          if (
            data.RatingScaleValueID === "" ||
            data.RatingScaleValueID === -1
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  function submitAssesmentDoc(
    ActionType,
    StepOwnerLabel,
    CurrentStepID,
    DocID
  ) {
    // if (ActionType === "Submit") setMessage("Submitting assessments...");
    // else if (ActionType === "Approve") setMessage("Approving assessments...");

    setLoading(true);
    let { ParameterAssessments } = payload;
    console.log("payload is: ", ParameterAssessments, " ", actorAssessment);
    let index = ParameterAssessments.findIndex(
      (item) => item?.Header.DocumentID === DocID
    );
    if (index === -1) return setLoading(false);

    //let assesmentsId = ParameterAssessments[index].Header.DocumentID;
    // let paramterMap = {};
    let ownerIndex =
      StepOwnerLabel === "Appraiser"
        ? 1
        : StepOwnerLabel === "Appraisee"
        ? 0
        : 2;
    let Parameters = ParameterAssessments[index].Data.Parameters;
    if (ActionType === "Submit") {
      const missingParameterAssessments = [];
      for (let i = 0; i < Parameters.length; i++) {
        const { ActorAssessments } = Parameters[i];
        if (
          ActorAssessments[ownerIndex].RatingScaleValueID === "" ||
          ActorAssessments[ownerIndex].RatingScaleValueID === -1
        ) {
          missingParameterAssessments.push(Parameters[i].ParameterID);
        }
        let arr = [];
        ActorAssessments.map((ele) => {});
      }
      console.log(
        Parameters,
        missingParameterAssessments,
        "missingParameterAssessments"
      );
      if (missingParameterAssessments.length > 0) {
        setMissingParameters(missingParameterAssessments);
        setActionError({
          type: 1,
          message: "Assessment Not Done For All Parameters",
        });
        setLoading(false);
        return;
      }
    }

    const ParameterAssessment = ParameterAssessments[index];
    const { Data } = ParameterAssessment;

    // This condition is a PATCH
    if (
      (StepOwnerLabel === "Appraiser" || StepOwnerLabel === "Moderator") &&
      getFinalPeriod(Data.ReviewPeriodType) === Data.ReviewPeriodUnit
    ) {
      const OtherDimensionID =
        DimensionPeriodData.EmployeePerformancePlanData.Dimensions.find(
          (d) => d.DimensionID !== dimensionData.DimensionID
        )?.DimensionID;
      setButtonLoading(true);
      getData({
        featureId: FeatureIDs.ParameterAsseessment,
        payload: {
          "Data.AppraiseeUserID":
            DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID,
          "Data.DimensionID": OtherDimensionID,
          "Data.EmployeePerformancePlanID":
            DimensionPeriodData.EmployeePerformancePlanID,
        },
        fields: ["Header.*", "Data.*", "Workflow.*"],
      }).then((res) => {
        if (typeof res !== "string" && res.Data.length > 0) {
          let OtherDimensionPeriodType = "";
          for (let i = 0; i < res.Data.length; i++) {
            if (res.Data[i].ReviewPeriodType !== "I") {
              OtherDimensionPeriodType = res.Data[i].ReviewPeriodType;
              break;
            }
          }

          if (!OtherDimensionPeriodType) {
            setActionError({
              type: 1,
              message:
                "Assessment is not available for final period in other dimension",
            });
            setButtonLoading(false);
            return;
          }

          const OtherDimensionPeriodUnit = getFinalPeriod(
            OtherDimensionPeriodType
          );

          const OtherDimensionFinalPeriod = res.Data.find(
            (doc) =>
              doc.ReviewPeriodType === OtherDimensionPeriodType &&
              doc.ReviewPeriodUnit === OtherDimensionPeriodUnit
          );
          if (!OtherDimensionFinalPeriod) {
            setActionError({
              type: 1,
              message:
                "Assessment is not available for final period in other dimension",
            });
            setButtonLoading(false);
            return;
          } else {
            const OtherDimensionParameters =
              OtherDimensionFinalPeriod.Parameters;
            if (StepOwnerLabel === "Moderator") {
              submitDimensionAssessmentDoc(
                ParameterAssessment,
                ActionType
              ).then(() => {
                console.log("Setting timeout");
                setTimeout(() => {
                  submitDimensionAssessmentDoc(
                    OtherDimensionFinalPeriod,
                    ActionType
                  )
                    .then((res2) => {
                      setActionError({
                        type: 0,
                        message: "Success",
                      });
                      handleTabEdit("save");
                      console.log("Result is: ", res2);
                    })
                    .finally(() => setButtonLoading(false));
                }, 3000);
              });
              return;
            }
            let isValidAssessment = true;
            OtherDimensionParameters.forEach((parameter) => {
              const assessments = parameter.ActorAssessments;
              const actorAssessment = assessments.find(
                (a) => a.ActorType === StepOwnerLabel
              );

              if (actorAssessment) {
                if (
                  !actorAssessment.RatingScaleValueID ||
                  actorAssessment.RatingScaleValueID === -1 ||
                  actorAssessment.RatingScaleValueID === ""
                ) {
                  isValidAssessment = false;
                }
              }
            });

            if (!isValidAssessment) {
              setActionError({
                type: 1,
                message:
                  "The final period assessment is not done for all parameters in other dimension",
              });
              setButtonLoading(false);
              return;
            } else {
              if (
                !DimensionPeriodData?.EmployeePerformancePlanData
                  ?.AppraiserOverallComments
              ) {
                setActionError({
                  type: 1,
                  message:
                    "Please submit the overall comments for this appraisee",
                });
                setButtonLoading(false);
                return;
              }

              setModal({
                Component: SubmitAlert,
                props: {
                  message:
                    "Caution: Ensure thorough completion of all appraisal sections before proceeding. Once advanced to the next stage, editing will be restricted. Double-check your inputs to avoid any unintended consequences.",
                  closeAlert: () => {
                    setModal(null);
                    setModalOpen(false);
                  },
                  onCancel: () => {
                    setButtonLoading(false);
                  },
                  onSubmit: () => {
                    submitDimensionAssessmentDoc(
                      ParameterAssessment,
                      ActionType
                    ).then(() => {
                      console.log("Setting timeout");
                      setTimeout(() => {
                        submitDimensionAssessmentDoc(
                          OtherDimensionFinalPeriod,
                          ActionType
                        )
                          .then((res2) => {
                            setActionError({
                              type: 0,
                              message: "Success",
                            });
                            handleTabEdit("save");
                            console.log("Result is: ", res2);
                            setModal({
                              Component: ActionDebouncer,
                            });
                            setModalOpen(true);
                            // window.location.reload();
                          })
                          .finally(() => setButtonLoading(false));
                      }, 3000);
                    });
                  },
                },
              });
              setModalOpen(true);
            }
          }
        } else {
          setActionError({
            type: 1,
            message: "Assessment not started for other dimension.",
          });
          setButtonLoading(false);
          return;
        }
      });
      return;
    } else if (
      StepOwnerLabel === "Appraiser" ||
      StepOwnerLabel === "Moderator"
    ) {
      const OtherDimensionID =
        DimensionPeriodData.EmployeePerformancePlanData.Dimensions.find(
          (d) => d.DimensionID !== dimensionData.DimensionID
        )?.DimensionID;
      setButtonLoading(true);
      getData({
        featureId: FeatureIDs.ParameterAsseessment,
        payload: {
          "Data.AppraiseeUserID":
            DimensionPeriodData.EmployeePerformancePlanData.AppraiseeUserID,
          "Data.DimensionID": OtherDimensionID,
          "Data.EmployeePerformancePlanID":
            DimensionPeriodData.EmployeePerformancePlanID,
        },
        fields: ["Header.*", "Data.*", "Workflow.*"],
      }).then((res) => {
        if (typeof res !== "string" && res.Data.length > 0) {
          const index = res.Data.findIndex((d) => d.WorkflowStatus !== "C");
          if (
            index !== -1 &&
            Data.ReviewPeriodUnit === res.Data[index].ReviewPeriodUnit
          ) {
            if (isValidAssessment(res.Data[index])) {
              setButtonLoading(true);
              editEPP()
                .then(() => {
                  editConfidentialAssessment().then(() => {
                    submitDimensionAssessmentDoc(
                      ParameterAssessment,
                      ActionType
                    )
                      .then(() => {
                        // setTimeout(() => {
                        submitDimensionAssessmentDoc(
                          res.Data[index],
                          ActionType
                        ).then(() => {
                          setActionError({
                            type: 0,
                            message: "Success",
                          });
                          handleTabEdit("save");
                          console.log("Result is: ", res);
                          setModal({
                            Component: ActionDebouncer,
                            props: {
                              message:
                                "Submitting Assessment for both goals and core values",
                            },
                          });
                          setModalOpen(true);
                          // window.location.reload();
                        });
                        // }, 1000);
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        console.log("LOGGED 2");
                        // setLoading(false);

                        //  console.log("Result is: ", res);
                      });
                  });
                })
                .finally(() => {
                  setButtonLoading(false);
                });
            } else {
              setActionError({
                type: 1,
                message:
                  "The same review period assessment is not done for all parameters in other dimension",
              });
              setButtonLoading(false);
              console.log("Result is: ", res);
            }
          } else {
            setButtonLoading(true);
            editEPP()
              .then(() => {
                editConfidentialAssessment().then(() => {
                  submitDimensionAssessmentDoc(ParameterAssessment, ActionType)
                    .then((res) => {
                      setActionError({
                        type: 0,
                        message: "Success",
                      });
                      handleTabEdit("save");
                      console.log("Result is: ", res);
                      setModal({
                        Component: ActionDebouncer,
                        props: {
                          message:
                            "Goals and Core Values are in different Review Periods, Submitting the current dimension only.",
                        },
                      });
                      setModalOpen(true);
                      // window.location.reload();
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                    .finally(() => {
                      console.log("LOGGED 2");
                      // setLoading(false);

                      //  console.log("Result is: ", res);
                    });
                });
              })
              .finally(() => {
                setButtonLoading(false);
              });
          }
        }
      });

      return;
    }

    setButtonLoading(true);
    submitDimensionAssessmentDoc(ParameterAssessment, ActionType)
      .then((res) => {
        setActionError({
          type: 0,
          message: "Success",
        });
        handleTabEdit("save");
        console.log("Result is: ", res);
        setModal({
          Component: ActionDebouncer,
        });
        setModalOpen(true);
        // window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        console.log("LOGGED 2");
        // setLoading(false);
        setButtonLoading(false);

        //  console.log("Result is: ", res);
      });
  }

  console.log("Workflow: ", MultipleWorkflowActions);
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Typography variant="body1" sx={{ margin: "0.5em 0" }}>
        {dimensionDescription}
      </Typography>
      <Box>
        <ListView
          secondGroups={secondGroup}
          firstGroups={firstGroup}
          parameters={{ ...parameter }}
        />
      </Box>
      {actionError && (
        <AlertMessage
          {...actionError}
          onClose={() => setActionError(null)}
          autoClose={true}
          autoCloseTime={3000}
        />
      )}
      {MultipleWorkflowActions.map(
        ({
          ReviewPeriodType,
          ReviewPeriodUnit,
          WorkflowActions,
          CurrentStepID,
          StepOwnerLabel,
          WorkflowAction,
          CurrentStepUserId,
          DocID,
        }) => {
          // This condition is a PATCH
          return StepOwnerLabel === "Reviewer" &&
            getFinalPeriod(ReviewPeriodType) === ReviewPeriodUnit ? (
            <></>
          ) : (
            <>
              {CurrentStepID && (
                <div className="Flex-row" style={{ gap: "1em" }}>
                  {MultipleWorkflowActions.length > 1 && (
                    <Typography variant="h5">
                      {ReviewPeriodType === "I"
                        ? `Interim ${
                            PeriodMap[PeriodMapper(ReviewPeriodUnit)].values[
                              ReviewPeriodUnit
                            ]
                          }`
                        : PeriodMap[ReviewPeriodType].values[ReviewPeriodUnit]}
                      :{" "}
                    </Typography>
                  )}
                  {isAppraiser() && (
                    <Button
                      title={"Save Plan"}
                      isLoading={appraiserLoading}
                      onClick={() => {
                        handleTabEdit("save");
                        saveEPPConfidentialGoalsCoreValues();
                      }}
                      style={{ margin: "2em" }}
                    />
                  )}
                  {CurrentStepUserId === userId ? (
                    WorkflowActions?.length > 0 &&
                    WorkflowActions.map((action) => {
                      return (
                        <Button
                          title={action}
                          isLoading={buttonLoading}
                          onClick={() => {
                            DimensionPhase === "G"
                              ? saveGoalSettingTargets(CurrentStepID, action)
                              : submitAssesmentDoc(
                                  action,
                                  StepOwnerLabel,
                                  CurrentStepID,
                                  DocID
                                );
                          }}
                          style={{ margin: "2em" }}
                        />
                      );
                      // return action === "Submit" ? (
                      //   <Button
                      //     title={"Submit"}
                      //     onClick={() => {
                      //       DimensionPhase === "G"
                      //         ? saveGoalSettingTargets(CurrentStepID, "Submit")
                      //         : submitAssesmentDoc("Submit");
                      //     }}
                      //     style={{ margin: "2em" }}
                      //   />
                      // ) : action === "Approve" ? (
                      //   <div className="Flex-row" style={{ gap: "1em" }}>
                      //     <Button
                      //       variant={"contained"}
                      //       otherProps={{ color: "success", startIcon: <Check /> }}
                      //       title={"Approve"}
                      //       onClick={() => {
                      //         DimensionPhase === "G"
                      //           ? saveGoalSettingTargets(CurrentStepID, "Approve")
                      //           : submitAssesmentDoc("Approve");
                      //       }}
                      //       style={{ margin: "2em" }}
                      //     />
                      //     {/* <Button
                      //   otherProps={{ color: "error", startIcon: <Clear /> }}
                      //   title={"Reject"}
                      //   onClick={() => {
                      //     DimensionPhase === "G"
                      //       ? saveGoalSettingTargets(CurrentStepID, "Reject")
                      //       : submitAssesmentDoc();
                      //   }}
                      //   style={{ margin: "2em" }}
                      // /> */}
                      //   </div>
                      // )
                    })
                  ) : (
                    <>{WorkflowAction}</>
                  )}
                </div>
              )}
            </>
          );
        }
      )}
      {modal && (
        <EwModal onClose={() => {}} open={modalOpen}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Box>
  );
};

export default DimensionPeriod;
